import {Controller} from "@hotwired/stimulus"
import {formSerialization} from "./mixins/form_serialization";
import {replaceByFetch} from "./mixins/replace_by_fetch";

/**
 * ModalChartController is very simple controller
 * that listens for date range form submissions
 * and replaces the modal content with the response.
 * It is used to generate changes in the charts
 * for Readings chart and Data points chart.
 *
 * Here was originally used TurboFrameController,
 * which we needed to get rid of.
 * The original intention was to replace it with TurboFrame,
 * but that didn't work as expected.
 */
export default class extends Controller {
  static values = {
    src: String
  }

  connect() {
    super.connect();
    formSerialization(this);
    replaceByFetch(this);
    this.element.addEventListener('click', (e) => {
      if (!this.scope.containsElement(e.target)) {
        return;
      }

      if (e.target.tagName === 'INPUT' && e.target.type === 'submit') {
        this.handleform(e)
      }
    });
  }

  handleform(e){
    e.preventDefault();
    let form = e.target.closest('form');
    if (!form) {
      return;
    }

    let url = form.action;
    const data = new FormData();
    this.collectInputValues(form, data)

    this.showSpinner();

    this.replaceByFetch(url, this.formMethod(form), {data: data});
  }

  formMethod(form) {
    const input = form.querySelector('[name=_method]');
    if (input) {
      return input.value.toUpperCase();
    }

    return 'POST';
  }
}