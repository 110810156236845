import {Controller} from "@hotwired/stimulus"

/**
 It prevents bullet chart ticks from visually overlapping. It assumes that
 the ticks are initially placed "inside" the bullet range and aligned as if
 the other tick did not exist. It registers an intersection observer to delay
 execution to when needed.

 The algorithm is:
  - if the right edge of the left tick is further to the right than the left
    edge of the right tick (requiring a gap between)
 - take this difference and move the ticks from each
 - if the space allows, move each out by 1/2 of the space needed
 - if that would push a tick out of the controlled element
    - move the constrained tick to the edge and
    - move the other tick further by the remainder

 It assumes that neither the ticks nor the controlled element have any padding.
 It assumes the controlled element is positioned and the ticks are absolutely
 positioned. Their left/right offset is modified as well as text alignment.

 Resizing is not handled.
 */
export default class extends Controller {
  static targets = ['leftTick', 'rightTick']

  gapSize = 5

  initialize() {
    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.dispatchEvent(new CustomEvent('becomes-visible'))
      }
    }, {threshold: [0]})
  }

  connect() {
    this.element.addEventListener('becomes-visible', () => {
      const leftWidth = this.leftTickTarget.clientWidth
      const rightWidth = this.rightTickTarget.clientWidth
      const availableSpace = this.element.clientWidth

      let spaceToCreate =
        this.leftTickTarget.offsetLeft +
        leftWidth +
        this.gapSize -
        this.rightTickTarget.offsetLeft
      if (spaceToCreate > 0) {
        if ((this.leftTickTarget.offsetLeft - spaceToCreate / 2) > 0) {
          if ((this.rightTickTarget.offsetLeft + rightWidth + spaceToCreate / 2) > availableSpace) {
            this.rightTickTarget.style = `right: 0; text-align: right`
            this.leftTickTarget.style = `left: ${this.rightTickTarget.offsetLeft - this.gapSize - leftWidth}px`
          } else {
            this.rightTickTarget.style = `left: ${this.rightTickTarget.offsetLeft + spaceToCreate / 2}px; text-align: right`
            this.leftTickTarget.style = `left: ${this.leftTickTarget.offsetLeft - spaceToCreate / 2}px`
          }
        } else {
          spaceToCreate -= this.leftTickTarget.offsetLeft
          this.leftTickTarget.style = `left: 0px`

          this.rightTickTarget.style = `left: ${this.rightTickTarget.offsetLeft + spaceToCreate}px; text-align: left`
        }
      }
    })
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }
}