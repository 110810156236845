import {Controller} from "@hotwired/stimulus"

/**
 *  ImportController allows
 *  1. adding new column(s) to import form .
 *  2. fill column with value set on header
 *
 *  example
 *
 *  *******************************************
 *  col1_heading   |  col2_heading  | add_button
 *  model1.attr_1  |  model1.attr_2 |
 *  model2.attr_1  |  model2.attr_2
 *  ********************************************
 *
 *  Clicking add_button inserts new column at the end, just before the add button.
 *
 *  ****************************************************************************
 *  col1_heading   |  col2_heading  | header_template_content | add_button
 *  model1.attr_1  |  model1.attr_2 | cell_template_content |
 *  model2.attr_1  |  model2.attr_2 | cell_template_content |
 *  ****************************************************************************
 *
 *  It expects 2 target templates - for header and for rest of cells in the column.
 *  Before inserting column cell, it would replace id & child_index placeholders with
 *  actual values.
 *
 *  Row
 *  Expects row(<tr>) to be marked as rowTarget, and also hold data attribute for actual id value
 *  to replace placeholder in cellTemplate
 *  sample_row:
 *  <tr data-id="1334" data-import-target="row">....</tr>
 *
 *
 *  Header Template
 *
 *      %template{data: {import_target: 'headerTemplate'}}
 *        %th
 *           %input.textfield.w-auto.p-1
 *           =link_to delete_icon, '#',
 *                    data: { action: 'import#deleteColumn' },
 *                    title: 'remove column',
 *                    class: 'delete-icon area-icon inline-flex'
 *
 *  Cell Template
 *
 *        %template{data: {import_target: 'cellTemplate'}}
 *          = f.fields_for :readings,
 *              BulkImport::Reading::Contract::ReadingForm.new(Reading::Presented.new),
 *              child_index: 'CHILD_INDEX' do |r|
 *              %td
 *                  = r.hidden_field :meter_id, value: 'ID_PLACEHOLDER'
 *                  = r.text_field :state, autocomplete: :off, class: 'border border-blue-200 p-2'
 */


export default class extends Controller {
    static targets = ['headerTemplate', 'cellTemplate', 'addButton', 'row', 'field']

    connect() {
        if (this.hasAddButtonTarget) {
            if (!this.hasHeaderTemplateTarget) {
                console.error('missing data-import-target for headerTemplate');
            }
            if (!this.hasCellTemplateTarget) {
                console.error('missing data-import-target for cellTemplate');
            }
        }
    }

    addColumn(event) {
        event.preventDefault()

        // insert header cell
        this.addButtonTarget.insertAdjacentHTML('beforebegin',this.headerTemplateTarget.innerHTML)
        let index = new Date().getTime()

        // insert cell at col_index position for each row
        this.rowTargets.forEach((row) => {
            var content = this.cellTemplateTarget.innerHTML.replace(/ID_PLACEHOLDER/g, row.dataset.id)
            content = content.replace(/CHILD_INDEX/g, index)
            row.lastElementChild.insertAdjacentHTML('beforebegin',content)
            index++
        })
    }

    deleteColumn(event) {
        event.preventDefault()

        let colIndex = event.target.closest('th').cellIndex

        // remove cell[col_index] for each row
        this.rowTargets.forEach((row) => {
            row.deleteCell(colIndex)
        })

        // remove header cell
        event.target.closest('th').remove()
    }

    fillColumn(event){
        let colIndex = event.target.closest('th').cellIndex
        this.fieldTargets.forEach((field) => {
            let index = field.closest('td').cellIndex
            if (index == colIndex) {
                field.value = event.target.value
            }
        })
    }
}