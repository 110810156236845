import {Controller} from "@hotwired/stimulus"
/**
 * Remove controller allows to remove DOM nodes, e.g. remove a placeholder once
 * content is loaded.
 */
export default class extends Controller {
  static targets = ['toRemove']

  remove() {
    this.toRemoveTargets.forEach(el => el.remove())
  }
}