import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches'
import {initChart} from "../../chart";
import {elmFlags} from "./mixins/elm_flags";

/**
 * Perpetual chart controller allows you to load a chart and keep reloading it.
 * It is used on the dashboard to keep charts up to date.
 */
export default class extends Controller {
  static targets = ['generalError', 'noDataError']
  static values = {
    url: String,
    refreshTimeout: Number
  }

  connect() {
    super.connect();
    fetches(this);
    elmFlags(this);

    this.loadChart(this.element);
  }

  loadChart(container) {
    const existingElmContainer = container.querySelector('[data-container=container]');
    container.dataset.controller = `${container.dataset.controller} spinner`;

    const elmContainer = document.createElement('div');
    elmContainer.dataset.container = 'container';
    // double wrapping because of Elm hot reloading
    const elmInnerContainer = document.createElement('div');
    elmContainer.appendChild(elmInnerContainer);
    container.appendChild(elmContainer);

    this.fetchJson(this.urlValue).then(res => {
      if (res.ok) {
        res.json().then(data => {
          if (Object.keys(data).length === 0) {
            elmContainer.remove();
            this.noDataErrorTarget.hidden = false;
          } else {
            if (existingElmContainer) {
              existingElmContainer.remove();
            }
            initChart(elmInnerContainer, this.augmentFlags({chart: data}));
          }
        })
      } else {
        elmContainer.remove();
        this.generalErrorTarget.hidden = false;
      }
    }).catch(_ => {
      elmContainer.remove();
      this.generalErrorTarget.hidden = false;

    }).finally(_ => {
      container.dataset.controller = container.dataset.controller.replace(' spinner','');
      if (this.hasRefreshTimeoutValue) {
        setTimeout(_ => {
          this.loadChart(container)
        }, this.refreshTimeoutValue);
      }
    });
  }
}