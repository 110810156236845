import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches';

/**
 * ReportSnapshotController
 *
 * - toggles discussion visibility
 * - swaps toggler title to match
 * - if a comment anchor is present at page load, opens its discussion and
 *   scrolls to the comment
 *
 * Atypically, a single instance of this controller can wrap multiple
 * toggler+discussion pairs and they need to be linked by an ID. This is because
 * of the scoring table report block where the toggler is in one table row &
 * the discussion in the other. The closest shared parent of those two rows is
 * <tbody> but that's shared by all rows, hence the linking via ID.
 *
 *  %table{data: { controller: 'report-snapshot'}}
 *   %tr
 *     %td
 *       %button{data: {action: 'click->report-snapshot#toggle',
 *                      report_snapshot_discussion_id: discussion_id}} Toggle me
 *   %tr{id: discussion_id, hidden: true, data: { report_snapshot_target: 'discussion'}}
 *     %td
 *       %form
 *       ...
 *
 * Additionally, this controller is able to fetch chunks of the report
 * asynchronously. Chunks are fetched one by one, in order, and inserted into
 * the DOM. This allows the user to start interacting with the page before all
 * chunks have been fetched.
 * To fetch a chunk, add a `chunk-reference` target to the element that should
 * contain the chunk and add a `chunk-reference-url-value` data attribute with
 * the URL to fetch the chunk from.
 *
 *  %div{data: { controller: 'report-snapshot'}}
 *    %div{data: { 'report-snapshot': { target: 'chunkReference', 'chunk-reference-url-value': <report-snapshot-chunk-url> }}}
 */
export default class extends Controller {
  static targets = ['discussion', 'chunkReference']

  connect() {
    fetches(this);

    this.userInteractedWithPage = false;
    this.listenToUserInteraction()
    this.scrollToAnchor(window.location.hash)

    // fetch all chunks in parallel so that the user doesn't have to wait for
    // all of them to load and can start moving around the page
    setTimeout(_ => {
      this.fetchAllChunksOneByOne()
    }, 0)
  }

  toggle(e) {
    const discussion = this.discussion(e.currentTarget.dataset.reportSnapshotDiscussionId);
    if (!discussion) {
      return;
    }

    if (discussion.hidden) {
      this.showDiscussion(discussion)
    } else {
      discussion.hidden = true;
      discussion.classList.add('hidden');
    }

    // toggle icon title
    const icon = e.currentTarget.querySelector('[data-alt-content]');
    const title = icon.querySelector('title');
    const currentText = title.textContent;
    title.textContent = icon.dataset.altContent;
    icon.dataset.altContent = currentText;
  }


  scrollToAnchor(hash) {
    if (this.userInteractedWithPage || !hash) {
      return;
    }

    if (hash.startsWith('#report_comment_')) {
      this.openDiscussionFromAnchor(hash)
    } else {
      this.scrollToBlockFromAnchor(hash)
    }
  }

  openDiscussionFromAnchor(hash) {
    const parts = hash.split('_');
    if (parts.length < 3){
      return;
    }

    const discussion = this.discussion(`discussion_${parts[2]}`);
    if (discussion) {
      this.showDiscussion(discussion);
      const comment = this.element.querySelector(`[id^="${hash.substring(1)}"]`);

      this.scrollTo(comment)
    }
  }

  scrollToBlockFromAnchor(hash) {
    const blockId = hash.substring(1)
    const block = this.element.querySelector(`[id^="${blockId}"]`);

    this.scrollTo(block)
  }

  showDiscussion(el) {
    el.hidden = false;
    el.classList.remove('hidden');
  }

  discussion(id) {
    const matching = this.discussionTargets.filter(d => d.id === id);
    if (matching.length === 0) {
      return null;
    } else {
      return matching[0];
    }
  }

  fetchAllChunksOneByOne() {
    // return
    const chunkReferences = this.chunkReferenceTargets;
    if (chunkReferences.length === 0) {
      return;
    }

    this.fetchChunk(chunkReferences[0], chunkReferences.slice(1));
  }

  fetchChunk(chunkReference, restOfTheChunks) {
    const url = chunkReference.dataset.reportSnapshotChunkReferenceUrlValue
    if (!url) {
      console.error('No URL for chunk reference', chunkReference);
      return;
    }

    const fetchRestOfTheChunks = _ => {
      if (restOfTheChunks.length > 0) {
          this.fetchChunk(restOfTheChunks[0], restOfTheChunks.slice(1));
      }
    }

    this.fetchPartial(url).then(res => {
      res.text().then(text => {
        const cannotReferenceElementThatGetsReplaced = chunkReference.parentElement;
        chunkReference.outerHTML = text;
        this.scrollToAnchor(window.location.hash)
        fetchRestOfTheChunks()
      })
    }).catch(e => {
      console.error('Failed to fetch chunk', e);
      fetchRestOfTheChunks()
    })
  }

  listenToUserInteraction() {
    window.addEventListener('click', this.setUserInteractedWithPage);
    window.addEventListener('scroll', this.setUserInteractedWithPage);
  }

  setUserInteractedWithPage() {
    this.userInteractedWithPage = true;
    window.removeEventListener('click', this.setUserInteractedWithPage);
    window.removeEventListener('scroll', this.setUserInteractedWithPage);
  }

  scrollTo(element) {
    if (element) {
      setTimeout(_ => window.scrollTo({ top: element.offsetTop, behavior: 'smooth' }), 100);
    }
  }
}