import {Controller} from "@hotwired/stimulus"
/**
 * ClassToggler controller allows to add or remove a class on the controller
 * element. The state is stored in Local Storage.
 * 
 * usage:
 * 
 * %div{data: {controller: 'class-toggler',
 *             class_toggler_to_toggle_class: "<the class to toggle>",
 *             class_toggler_key_value: "<the key for the stored element>"  }}  #controller element
 * 
 *   %button{data: {action: 'click->class-toggler#add'}}
 * 
 *   %button{data: {action: 'click->class-toggler#remove'}}
 */
export default class extends Controller {
    static values = { key: String }
    static classes = ['toToggle']

    connect(){
        this.initFromLocalStorage()
    }

    initFromLocalStorage() {
        const storedClass = localStorage.getItem(this.keyValue)
        if (storedClass && this.element) {
          this.element.classList.add(this.toToggleClass)
        }
    }
    
    add(){
      this.element.classList.add(this.toToggleClass)
      localStorage.setItem(this.keyValue, this.toToggleClass)
    }

    remove(){
      this.element.classList.remove(this.toToggleClass)
      localStorage.removeItem(this.keyValue)
    }

}