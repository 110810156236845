import {Controller} from "@hotwired/stimulus"

/**
 * Navigatable checkbox controller allows to navigate based on the
 * checkbox being checked. It requires two url `checkedUrl` and `uncheckedUrl`.
 *
 * On `navigate`, the controller automatically moves the window location to
 * the related url (checked or unchecked)
 */
export default class extends Controller {
  static values = { checkedUrl: String, uncheckedUrl: String }

  navigate(event) {
    const link = document.createElement('a');
    link.classList.add('hidden');
    if (event.target.checked) {
      link.href = this.checkedUrlValue;
    } else {
      link.href = this.uncheckedUrlValue;
    }
    event.target.parentNode.appendChild(link);
    link.click();
  }
}