import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches'

export default class extends Controller {
  static targets = ['startTime', 'delay', 'period', 'partial']

  connect() {
    super.connect();
    fetches(this)
  }

  update() {
    let relativeUrl = this.startTimeTarget.dataset.url;
    if (!relativeUrl.startsWith('http')) {
      relativeUrl = window.location.origin + relativeUrl;
    }

    const url = new URL(relativeUrl);

    const params = new URLSearchParams(url.search)
    params.set('start_time', this.startTimeTarget.value)
    params.set('delay', this.delayTarget.value)
    params.set('period', this.periodTarget.value)
    url.search = params.toString()

    const urlWithStartTime = url.toString()

    this.fetchPartial(urlWithStartTime).then(res => {
      res.text().then(partial => {
        this.partialTarget.innerHTML = partial
      })
    })
  }
}