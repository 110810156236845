import {Controller} from "@hotwired/stimulus"
import OverlappingMarkerSpiderfier from "../helpers/oms.min.js"
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
import { Loader } from "@googlemaps/js-api-loader"

const maxZoom = 16;

/** MapController allows to load GoogleMaps and show a list of pointers.
 *
 * The controller takes care of loading the necessary JS & waits for it to
 * load, at most `loadWaitAttempt * loadWaitTimeout` ms. For historical reasons,
 * some libraries are pre-loaded as part of the compiled bundle :shrug:. API
 * key and locale for the map must be provided to the controller.
 *
 * Markers to be rendered consist of
 *
 *    { lat: Float, lon: Float, title: String }
 *
 * Optionally, `data_quality` key can be included, must be a % between
 * 0 and 100. HTML classes `pinlabel` and `pinLabel-N` (where N is in (0..10),
 * in uniform increments) will be applied to such pins.
 **/
export default class extends Controller {
  static classes = ['pinLabel'];
  static values = {
    apiKey: String,
    locale: String,
    markers: Array
  }

  connect() {
    super.connect();

    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      language: this.localeValue,
    });

    this.loader.load().then(async () => {
      await this._show();
    });
  }

  async _show() {
    const map = await this._map();

    const bounds = new google.maps.LatLngBounds();
    const spiderfier = this._spiderfier(map)
    const clusterer = this._clusterer(map)

    const markers = async () => this.markersValue.map(async (m) => {
      const marker = await this._createMarker(map, m);
      spiderfier.trackMarker(marker);
      bounds.extend(marker.position);
      return marker;
    });

    const loadedMarkers= await Promise.all(await markers());
    map.fitBounds(bounds)

    google.maps.event.addListener(map, "tilesloaded", function () {
      clusterer.clearMarkers();
      clusterer.addMarkers(loadedMarkers.filter(function (marker) {
        return map.getBounds().contains(marker.position);
      }))
    });

    google.maps.event.addListener(map, 'idle', () => {
      this._openAllClusters(map, spiderfier)
    });
  }

  _clusterer(map) {
    return new MarkerClusterer({
      map: map,
      algorithm: new SuperClusterAlgorithm({maxZoom: 15}),
    });
  }

  _spiderfier(map) {
    return new OverlappingMarkerSpiderfier(
      map,
      {
        basicFormatEvents: true,
        circleFootSeparation: 40,
        keepSpiderfied: true,
        markersWontHide: true,
        markersWontMove: true,
      }
    );
  }

  async _map() {
    let map;
    const { Map } = await google.maps.importLibrary("maps");
    map = new Map(this.element, {
      zoom: maxZoom,
      zoom_changed: function () {
        if (map.getZoom() > maxZoom) {
          map.setZoom(maxZoom)
        }
      },
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{visibility: "off"}]
        }
      ],
      mapId: "map_with_markers",
    });

    return map
  }

  _createMarker(map, data) {
    if (data.data_quality !== undefined && data.data_quality !== null) {
      return this._markerWithDataQuality(map, data);
    } else {
      return this._basicMarker(map, data);
    }
  }

  async _basicMarker(map, data) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    return new google.maps.marker.AdvancedMarkerElement({
      position: new google.maps.LatLng(parseFloat(data.lat), parseFloat(data.lng)),
      map: map,
      title: data.title,
    })
  }

  _markerWithDataQuality(map, data) {
    const labelContent = `${data.data_quality} %`

    const x_pos = -labelContent.length - 11
    const percentage_for_class = data.data_quality - (data.data_quality % 10)

    return new MarkerWithLabel({
      position: new google.maps.LatLng(data.lat, data.lng),
      clickable: false,
      draggable: false,
      map: map,
      labelContent: labelContent,
      labelAnchor: new google.maps.Point(x_pos, -80),
      labelClass: 'block p-2 rounded-lg ' +
            'border-2 border-solid bg-white text-center '+
            `${this.pinLabelClass}-${percentage_for_class}`,
      title: data.title,
    });
  }

  _openAllClusters(map, spiderfier) {
    if (map.getZoom() <= this._maxClustererZoom()) {
      return;
    }

    spiderfier.markersNearAnyOtherMarker().forEach(function (marker) {
      google.maps.event.trigger(marker, 'click')
    })
  }

  _maxClustererZoom() {
    return maxZoom - 1
  }
}