import {Controller} from "@hotwired/stimulus"

const localStorageKey = "exports";

/**
 * Exports controller is a single purpose controller, very tied to the markup.
 * It has two main functions with a variation.
 *
 * (1) When you create an export and the page is rerendered, it grabs
 *     `monitor_export` query param, finds an export row based on the ID and
 *     sends the information to a shared worker. The shared worker polls for
 *     state changes. When a state change occurs, it is updated in the exports
 *     table. When it gets to the `generated` state, a link is written +
 *     an iframe is created to autodownload.
 * (1b) If you navigate away after the export creation (or have another tab
 *      opened), the monitoring is still started like in (1). Because other
 *      pages don't have an export table, a small popup is rendered in
 *      the bottom right corner with just the monitored exports. The state in
 *      there changes the same way except the export is not autodownloaded -
 *      we don't know how many tabs are open and don't want to risk multiple
 *      downloads.
 * (2) Scheduled export e-mails navigates you to the schedule edit page which
 *     contains an exports table. The controller picks up `download_export`
 *     query param and starts download of that export.
 *
 * In order to make it happen, the controller has be instantiated on every page
 * in the application but in most of them, it's just templates for the popup.
 */
export default class extends Controller {
  static targets = ['table', 'dialogTemplate', 'pollRowTemplate', 'dialog'];
  static classes = ['pollDialog', 'closeDialog', 'fresh']
  static values = {
    autoDownload: Boolean
  }

  connect() {
    this._connectWorker();
    this._startMonitoring();
    this._downloadExport();
  }

  _downloadExport() {
    if (!this.hasTableTarget) {
      return
    }

    window.location.search.split('?')[1].split('&').forEach((param) => {
      const [key, val] = param.split('=');

      if (key === 'download_export') {
        const id = parseInt(val, 10);
        const stateCell = this._exportRow(this.tableTarget, id).querySelector('[data-exports-field="state"]');
        const downloadLink = stateCell.querySelector('a')
        if (downloadLink) {
          this._autoDownload(stateCell, downloadLink.href);
        }
      }
    })
  }

  _startMonitoring() {
    if (!this.hasTableTarget) {
      return
    }

    window.location.search.split('?')[1].split('&').forEach((param) => {
      const [key, val] = param.split('=');

      if(key === 'monitor_export') {
        const id = parseInt(val, 10);
        const row = this._exportRow(this.tableTarget, id);

        this.worker.port.postMessage({
          type: 'start_monitoring',
          id: id,
          name: row.querySelector('[data-exports-field="name"]').innerText,
          date: row.querySelector('[data-exports-field="date"]').innerText,
          state: this._rowState(row),
          url: '',
        })
      }
    })
  }

  _connectWorker() {
    this.worker = new SharedWorker(new URL("../../entrypoints/worker.js", import.meta.url));
    this.worker.port.onmessage = (e) => {
      switch (e.data.type) {
        case 'is_active':
          this._onIsActive(e.data);
          break;
        case 'change':
          this._onExportStateChange(e.data);
          break;
        case 'store':
          localStorage.setItem(localStorageKey, JSON.stringify(e.data.exports))
      }
    }

    this.worker.port.postMessage({
      type: 'init',
      base_url: window.location.host,
      csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    })

    this.worker.port.postMessage({
      action: "load_local_state",
      exports: JSON.parse(localStorage.getItem(localStorageKey)) || []
    })

    window.addEventListener('unload', () => {
      this.worker.port.postMessage({type: 'close'});
    })
  }

  _onIsActive(data) {
    if (!this.hasTableTarget && data.is_active) {
      this._initPollDialog(data.exports)
    }
  }

  _initPollDialog(exports) {
    const dialog = document.createElement('div')
    dialog.dataset.exportsTarget = 'dialog'
    dialog.innerHTML = this.dialogTemplateTarget.innerHTML;
    this.dialogTemplateTarget.remove();

    const tbody = dialog.querySelector('tbody')
    exports.forEach(exp => {
      tbody.appendChild(this._newPollRow(exp))
    });

    this.element.appendChild(dialog);
    this.element.classList.remove('hidden');
  }

  closeDialog() {
    if (this.hasDialogTarget) {
      this.dialogTarget.remove();
    }
  }

  _newPollRow(exp) {
    let state = this._localizedState(exp);
    if (this._isGenerated(exp)) {
      state = this._downloadLink(exp);
    }

    const renderer = document.createElement('template')
    renderer.innerHTML = this.pollRowTemplateTarget.innerHTML.
    replace('{{id}}', exp.id).
    replace('{{name}}', exp.name).
    replace('{{date}}', exp.date).
    replaceAll('{{state}}', state)
    return renderer.content;
  }

  _localizedState(exp) {
    return window.I18n.t('export.state.' + exp.state);
  }

  _isGenerated(exp) {
    return exp.state === 'generated';
  }

  _downloadLink(exp) {
    return `<a href="${exp.url}" target="_blank" class="link">${window.I18n.t('download')}</a>`;
  }

  _onExportStateChange(data) {
    let table = null
    if (!this.hasTableTarget) {
      if (!this.hasDialogTarget) {
        this._initPollDialog([data.export]);
      }
      table = this.dialogTarget.querySelector('table');
    }

    if (!table && this.hasTableTarget) {
      table = this.tableTarget;
    }

    if (!table) {
      console.error('exports table available not available in either a dialog or page');
      return;
    }

    this._updateExportState(table, data.export)
  }

  _updateExportState(table, exp) {
    const row = this._exportRow(table, exp.id);
    if (row) {
      if (this._rowState(row) !== exp.state) {
        const stateCell = row.querySelector('[data-exports-field="state"]');
        stateCell.classList.remove(this.freshClass);

        let state = this._localizedState(exp);
        if (this._isGenerated(exp)) {
          state = this._downloadLink(exp);
        }
        stateCell.innerHTML = state;
        setTimeout(() => {
          stateCell.classList.add(this.freshClass);
        }, 200)

        if (this.autoDownloadValue && this._isGenerated(exp)) {
          this._autoDownload(stateCell, exp.url)
        }
        row.dataset['exportsState'] = exp.state;
      }
    } else {
      const row = document.importNode(this._newPollRow(exp), true);
      table.querySelector('tbody').append(row);
    }
  }

  _rowState(row) {
    return row.dataset['exportsState'];
  }

  _exportRow(table, id) {
    return table.querySelector(`[data-exports-id="${id}"]`);
  }

  _autoDownload(cell, url) {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.classList.add('hidden');
    cell.append(iframe);
  }
}