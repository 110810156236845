import {Controller} from "@hotwired/stimulus"

/*
 * Entity selector controller handles a set of checkboxes in a tree, selecting
 * 1-n entities.
 *
 * If you need to select 0-1 entities, use EntityPickerController.
 *
 * On connecting or enforceRules call, the controller will enforce one of two sets of rules.
 *
 * ## Node mode - selecting entities
 *
 * 1. If no checkboxes are checked, the first checkbox will be checked.
 * 2. If one checkbox is checked, it will be disabled and a hidden input will
 *    be added next to it to take place in form serialization.
 * 3. If more than one checkbox is checked, all checkboxes will be enabled and
 *    any hidden inputs will be removed.
 *
 * ## Branch mode - selecting branches
 *
 * When a node's checkbox is checked, all its descendant's checkboxes will be
 * unselected and so will all ancestor's checkboxes. The checkboxes must have
 * path param filled in to determine the tree structure.
 *
 * ## Example
 *
 *    %div{data: {controller: "entities-selector", entities_selector_mode: "node"}}
 *     %input{type: :checkbox,
 *           data: { action: "change->entities-selector#enforceRules",
 *                   entities_selector_target: :checkbox }}
 *     %input{type: :checkbox,
 *           data: { action: "change->entities-selector#enforceRules",
 *                   entities_selector_target: :checkbox }}
 *     %input{type: :checkbox,
 *           data: { action: "change->entities-selector#enforceRules",
 *                   entities_selector_target: :checkbox }}
 *
 */
export default class extends Controller {
  static targets = ['checkbox'];
  static values = {mode: String};

  connect() {
    super.connect();
    this.enforceRules();

    this.listener = this.enforceRules.bind(this)
    window.addEventListener('alternatives:select', this.listener);
  }

  disconnect() {
    super.disconnect();

    window.removeEventListener('alternatives:select', this.listener);
  }

  enforceRules(e) {
    switch (this.modeValue) {
      case 'branch':
        if (e) {
          this._enforceBranchMode(e);
        } else {
          this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked) {
              this._enforceBranchModeHelp(checkbox);
            }
          });
        }
        break;
      case 'node':
        this._enforceNodeMode();
        break;
      default:
        console.error('Invalid mode', this.modeValue);
    }
  }

  _enforceBranchMode(e) {
    this._enforceBranchModeHelp(e.target);
  }

  _enforceBranchModeHelp(checkbox) {
    if (!checkbox.checked) {
      return;
    }

    const path = checkbox.dataset.entitiesSelectorPathParam;
    const ancestorsPaths = this._ancestorsPaths(path);

    this.checkboxTargets.forEach((otherCheckbox) => {
      if (otherCheckbox === checkbox) {
        return;
      }

      // unselect descendant
      const otherPath = otherCheckbox.dataset.entitiesSelectorPathParam;
      if (otherPath.startsWith(`${path}/`)) {
        otherCheckbox.checked = false;
        return;
      }

      // unselect ancestor
      if (ancestorsPaths.includes(otherPath)) {
        otherCheckbox.checked = false;
      }
    })
  }

  _ancestorsPaths(path) {
    const segments = path.split('/');
    const result = [];
    for (let i = 1; i < segments.length; i++) {
      result.push(segments.slice(0, i).join('/'));
    }
    return result;
  }


  _enforceNodeMode() {
    let selectedCount = 0;
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedCount++;
      }
    });

    switch (selectedCount) {
      case 0:
        this._checkFirstCheckbox();
        this._disableLoneChecked();
        break;
      case 1:
        this._disableLoneChecked();
        break;
      default:
        this._enableAllChecked();
    }
  }

  _checkFirstCheckbox() {
    let checked = false;
    this.checkboxTargets.forEach((checkbox) => {
      const disabledOnlyTemporarily = checkbox.disabled && checkbox.dataset.keepDisabled !== 'true';
      const enabled = !checkbox.disabled || disabledOnlyTemporarily;
      const eligibleToCheck = !checked && enabled;

      if (eligibleToCheck) {
        checked = true;
        checkbox.checked = true;
      }
    })
  }

  _disableLoneChecked() {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.disabled = true;
        let hiddenInput = checkbox.parentNode.querySelector('input[type=hidden]');
        if (!hiddenInput) {
          hiddenInput = document.createElement('input');
          hiddenInput.type = 'hidden';
          hiddenInput.name = checkbox.name;
          hiddenInput.value = checkbox.value;
          checkbox.parentNode.appendChild(hiddenInput);
        }
      }
    });
  }

  _enableAllChecked() {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.disabled = false;
        const hiddenInput = checkbox.parentNode.querySelector('input[type=hidden]');
        if (hiddenInput) {
          hiddenInput.remove();
        }
      }
    });
  }
}