import {Controller} from "@hotwired/stimulus"
import {disabling} from "./mixins/disabling";

/**
 * TreeMenuController allows to control left tree entities menu.
 * The controller allows to expand/collapse all tree elements,
 * expand/collapse tree elements, find the current element and scroll to it.
 * The state is stored in Local Storage.
 *
 * To improve UX/UI - if you select an item in the tree menu,
 * the container automatically scrolls so that the entity
 * is vertically centered relative to the container.
 *
 * A list of expanded IDs can be passed as a value. This list will be expanded,
 * regardless of the state in the local storage. This is useful for tree used
 * for entity selection.
 *
 * %div{data: {
 *  controller: 'tree-menu',
 *  tree_menu_current_class: 'current',
 *  tree_menu_hidden_class: 'hidden',
 *  tree_menu_arrow_expanded_class: 'arrow-expanded',
 *  tree_menu_arrow_collapsed_class: 'arrow-collapsed',
 *  }}
 *
 *   %button{data: { action: 'click->tree-menu#findCurrent' }}
 *    'open only necessary nodes and scroll to current'
 *   %button{data: { action: 'click->tree-menu#expandAll' }} 'collapse all'
 *   %button{data: { action: 'click->tree-menu#collapseAll' }} 'expand all'
 *
 *   %div{data: { tree_menu_target: 'scrollContainer' }}
 *      %ul{data: { tree_menu_target: 'treeMenuRoot' }} 'Root level'
 *        %li 'First level'
 *          %div{data: { tree_menu_target: 'node' }}
 *            %button{type: :button,
 *                          data: { action: 'click->tree-menu#expandOrCollapse',
 *                                  id: entity_id }}
 *            %a{data: { id: entity_id, click->tree-menu#clickAndCenter }}
 *          %ul{data: { tree_menu_target: 'subtree', id: entity_id }} 'Second level'
 *
 *       %li 'First level'
 *          %div{data: { tree_menu_target: 'node' }}
 *            %button{type: :button,
 *                          data: { action: 'click->tree-menu#expandOrCollapse',
 *                                  id: entity_id }}
 *            %a{data: { id: entity_id, click->tree-menu#clickAndCenter }}
 *          %ul{data: { tree_menu_target: 'subtree', id: entity_id }} 'Second level'
 *
 *  Data structure:
 *  - treeStates: {customer_id: {opened: [1, 2, 3], entityId: 1, expiration: 1234567890}}
 *  - currentTreeState: {opened: [1, 2, 3], entityId: 1, expiration: 1234567890}
 *  - currentScrollPositions: {customer_id: {top: 100, expiration: 1234567890}}
 *
 *  So there are only two local storage keys for user:
 *  - treeMenuStates
 *  - treeScrollPosition
 *
 *  In each key, the individual parameters are grouped
 *  according to the current customer id.
 *
 *  In order not to pollute localStorage unnecessarily,
 *  each subkey has its own expiration (three months).
 *  When it expires, the subkey is deleted.
 */

export default class extends Controller {

  static values = {
    treeType: String,
    expandedIds: Array,
  }

  treeStates = {};
  currentTreeState = {opened: [], expiration: 0, entityId: ''};
  currentScrollPositions = {
    [Number(window.hb_customer_id)]: {entityId: 0, top: 0, expiration: 0}
  };

  static targets = [
    'node',
    'subtree',
    'scrollContainer',
    'treeMenuRoot',
  ]
  static classes = [
    'current',
    'arrowCollapsed',
    'arrowExpanded',
    'hidden'
  ]

  initialize() {
    super.initialize();
    disabling(this);
  }

  scrollContainerTargetConnected() {
    this.restoreTreeMenu();
  }

  findCurrent() {
    if (!this.hasTreeMenuRootTarget) return;

    const currentNode = this._currentNode();
    if (currentNode) {
      this._recursiveExpand(currentNode);
      this._saveTreeMenuState();
      this._scrollToCurrent(true);
    }
  }

  expandAll(e) {
    if (!this.hasTreeMenuRootTarget) return;

    this._togglerButtons().forEach((button) => {
      this._expandOrCollapse(button, true)
    })
    this._saveTreeMenuState();
  }

  collapseAll(e) {
    if (!this.hasTreeMenuRootTarget) return;

    this._togglerButtons().forEach((button) => {
      this._expandOrCollapse(button, false)
    })
    this._saveTreeMenuState();
  }

  _togglerButtons() {
    return this.element.querySelectorAll('[data-action="click->tree-menu#expandOrCollapse"]');
  }

  _recursiveExpand(element) {
    if (!element) return;

    const subtreeId = element.closest('[data-tree-menu-target="subtree"]')?.dataset?.id;
    if (subtreeId) {
      const toggler = Array.from(this._togglerButtons()).find(button => button.dataset.id === subtreeId);
      this._expandOrCollapse(toggler, true);
      this._recursiveExpand(toggler);
    }
  }

  // must handle clicks from search result
  clickAndCenter(e) {
    const targetId = e.currentTarget.dataset.id;
    if (!targetId) return;

    this._moveHighlightToNode(targetId);
    this._recursiveExpand(this._currentNode());
    this._saveTreeMenuState();
    this._scrollToCurrent();
  }

  expandOrCollapse(e) {
    this._expandOrCollapse(e.currentTarget);
    this._saveTreeMenuState();
  }

  _expandOrCollapse(togglerButton, open = null) {
    const currentId = togglerButton.dataset.id;
    const subtree = this.subtreeTargets.find(container => container.dataset.id === currentId);
    if (open === null) open = subtree.hidden || false

    if (open) {
      togglerButton.classList.remove(this.arrowCollapsedClass);
      togglerButton.classList.add(this.arrowExpandedClass);
      this.enable(subtree);
    } else {
      togglerButton.classList.remove(this.arrowExpandedClass);
      togglerButton.classList.add(this.arrowCollapsedClass);
      this.disable(subtree);
    }

    this._setTreeNodeState(open, currentId);
  }

  _setTreeNodeState(open, currentId) {
    if (open) {
      this.currentTreeState['opened'].push(currentId);
    } else {
      const index = this.currentTreeState['opened'].indexOf(currentId);
      if (index > -1) {
        this.currentTreeState['opened'].splice(index, 1);
      }
    }
    this.currentTreeState['opened'] = [...new Set(this.currentTreeState['opened'])];
  }

  _scrollToCurrent(save = true) {
    if (this.treeTypeValue !== 'sidebar') return;

    const currentNode = this._currentNode();
    if (!currentNode) return;

    const currentElementRectangle = currentNode.getBoundingClientRect();
    const treeMenuHolderRectangle = this.treeMenuRootTarget.getBoundingClientRect();
    const scrollContainer = this.scrollContainerTarget;
    const scrollContainerRectangle = scrollContainer.getBoundingClientRect();

    const top = currentElementRectangle.top
      - treeMenuHolderRectangle.top
      - scrollContainerRectangle.height / 2
      + currentElementRectangle.height / 2;

    scrollContainer.scrollTop = top;

    if (save) {
      this._saveScrollPosition(top);
    }
  }

  _moveHighlightToNode(id) {
    this.nodeTargets.forEach(wrapper => {
      wrapper.classList.toggle(this.currentClass, wrapper.dataset.id === id);
    });
  }

  _saveScrollPosition(top) {
    const date = new Date();
    date.setDate(date.getDate() + 90)

    this.currentScrollPositions[Number(window.hb_customer_id)] = {
      top: top,
      expiration: date.getTime(),
    };

    this.currentScrollPositions = this._discardExpiredEntries(this.currentScrollPositions);
    localStorage.setItem('treeScrollPosition', JSON.stringify(this.currentScrollPositions));
  }

  _saveTreeMenuState() {
    const date = new Date();
    date.setDate(date.getDate() + 90);
    this.currentTreeState['expiration'] = date.getTime();
    this.currentTreeState['opened'] = [...new Set(this.currentTreeState['opened'])];
    this.treeStates[Number(window.hb_customer_id)] = this.currentTreeState;

    this.treeStates = this._discardExpiredEntries(this.treeStates)
    localStorage.setItem('sidebarTreeStates', JSON.stringify(this.treeStates));
  }

  _discardExpiredEntries(o) {
    for (const [key, value] of Object.entries(o)) {
      if (value.expiration < Date.now()) {
        delete o[key];
      }
    }

    return o
  }

  restoreTreeMenu() {
    if (!this.hasTreeMenuRootTarget) return;

    this._restoreTreeMenuState();
    this._restoreScrollPosition();
  }

  _restoreScrollPosition() {
    const scrollPositionsFromStorage = localStorage.getItem('treeScrollPosition');

    if (scrollPositionsFromStorage && scrollPositionsFromStorage !== 'undefined') {
      const scrollPositions = JSON.parse(scrollPositionsFromStorage);
      const currentScrollPositions = scrollPositions[Number(window.hb_customer_id)];
      if (!currentScrollPositions) {
        scrollPositions[Number(window.hb_customer_id)] = {};
      }
      this.currentScrollPositions = scrollPositions;
      this._scrollToCurrent(true);
    }
  }

  _restoreTreeMenuState() {
    let treeStatesFromStorage = localStorage.getItem('sidebarTreeStates');

    if (treeStatesFromStorage && treeStatesFromStorage !== 'undefined') {
      this.treeStates = JSON.parse(treeStatesFromStorage);
      this.currentTreeState = this.treeStates[Number(window.hb_customer_id)] || {};
      this._openTreeMenu();
    } else {
      this.currentTreeState['entityId'] = this.getCurrentEntityId();
      this.treeStates[Number(window.hb_customer_id)] = this.currentTreeState;
    }
    this._recursiveExpand(this._currentNode());

    this.expandedIdsValue.forEach(id => {
      const currentNode = this.nodeTargets.find(wrapper => wrapper.dataset.id === id);
      this._recursiveExpand(currentNode);
    })
  }

  _openTreeMenu() {
    this.currentTreeState['opened'] = [...new Set(this.currentTreeState['opened'])];
    this._togglerButtons().forEach((button) => {
      if (this.currentTreeState['opened'].includes(button.dataset.id)) {
        this._expandOrCollapse(button, true);
      }
    });
  }

  getCurrentEntityId() {
    return this._currentNode()?.dataset?.id; // this is null on pre-fetch
  }

  _currentNode() {
    return this.nodeTargets.find(wrapper => wrapper.classList.contains(this.currentClass));
  }
}
