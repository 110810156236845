import Honeybadger from '@honeybadger-io/js'
import {Application} from "@hotwired/stimulus"
import ToggleController from "./javascript/controllers/toggle_controller"
import TabsController from "./javascript/controllers/tabs_controller"
import BulletChartController from "./javascript/controllers/bullet_chart_controller"
import SortableController from "./javascript/controllers/sortable_controller"
import NavigatableCheckboxController from "./javascript/controllers/navigatable_checkbox_controller"
import ModalChartController from "./javascript/controllers/modal_chart_controller"
import Clipboard from "./javascript/controllers/clipboard_controller"
import ImportController from "./javascript/controllers/import_controller"
import Alternatives from "./javascript/controllers/alternatives_controller"
import Remove from "./javascript/controllers/remove_controller"
import Form from "./javascript/controllers/form_controller"
import WindowEventsController from "./javascript/controllers/window_events_controller"
import ReconciliationController from "./javascript/controllers/reconciliation_controller"
import SpinnerController from "./javascript/controllers/spinner_controller"
import RangeController from "./javascript/controllers/range_controller"
import ReportConfigurationController from "./javascript/controllers/report_configuration_controller"
import ReportSnapshotController from "./javascript/controllers/report_snapshot_controller"
import PerpetualChartController from "./javascript/controllers/perpetual_chart_controller"
import RefreshFrameController from "./javascript/controllers/refresh_frame_controller"
import TemplateController from "./javascript/controllers/template_controller"
import DelayedContentController from "./javascript/controllers/delayed_content_controller"
import NextRangeController from "./javascript/controllers/next_range_controller"
import ReleaseNotesController from "./javascript/controllers/release_notes_controller"
import ClassTogglerController from "./javascript/controllers/class_toggler_controller"
import AreasController from "./javascript/controllers/areas_controller"
import ExportsController from "./javascript/controllers/exports_controller"
import ClickController from "./javascript/controllers/click_controller"
import MenuController from "./javascript/controllers/menu_controller"
import FlashController from "./javascript/controllers/flash_controller"
import MapController from "./javascript/controllers/map_controller"
import DialogController from "./javascript/controllers/dialog_controller"
import RedirectController from "./javascript/controllers/redirect_controller"
import ColumnsController from "./javascript/controllers/columns_controller"
import ProfilesController from "./javascript/controllers/profiles_controller"
import EntityPickerController from "./javascript/controllers/entity_picker_controller"
import EntitiesSelectorController from "./javascript/controllers/entities_selector_controller"
import SearchController from "./javascript/controllers/search_controller"
import HighlightController from "./javascript/controllers/highlight_controller"
import PropertyController from "./javascript/controllers/property_controller"
import DatepickerController from "./javascript/controllers/datepicker_controller"
import ReplaceContentController from "./javascript/controllers/replace_content_controller"
import ToggleSecretController from "./javascript/controllers/toggle_secret_controller"
import VirtualSourcesController from "./javascript/controllers/virtual_sources_controller"
import BrowserViewportsController from "./javascript/controllers/browser_viewports_controller"
import TreeMenuController from "./javascript/controllers/tree_menu_controller"
import ColumnsSettingsController from "./javascript/controllers/columns_settings_controller"
import ElmController from "./javascript/controllers/elm_controller"
import DetailsController from "./javascript/controllers/details_controller"
import * as Turbo from "@hotwired/turbo"
import turboHelpers from "./javascript/turbo/turbo-helpers"

turboHelpers();

/** Enable TurboDrive by default */
Turbo.session.drive = true;

Honeybadger.configure({
  apiKey: window.hb_api_key,
  environment: window.hb_current_environment,
})

Honeybadger.setContext({
  user_id: window.hb_current_user_id,
})

const application = Application.start()
application.register("tabs", TabsController)
application.register("bullet-chart", BulletChartController)
application.register("toggle", ToggleController)
application.register("sortable", SortableController)
application.register("navigatable-checkbox", NavigatableCheckboxController)
application.register("modal-chart", ModalChartController)
application.register("clipboard", Clipboard)
application.register("alternatives", Alternatives)
application.register("remove", Remove)
application.register("form", Form)
application.register("window-events", WindowEventsController)
application.register("report-snapshot", ReportSnapshotController)
application.register("report-configuration", ReportConfigurationController)
application.register("import", ImportController)
application.register("reconciliation", ReconciliationController)
application.register("spinner", SpinnerController)
application.register("perpetual-chart", PerpetualChartController)
application.register("range", RangeController)
application.register("refresh-frame", RefreshFrameController)
application.register("template", TemplateController)
application.register("delayed-content", DelayedContentController)
application.register("next-range", NextRangeController)
application.register("release-notes", ReleaseNotesController)
application.register("class-toggler", ClassTogglerController)
application.register("areas", AreasController)
application.register("exports", ExportsController)
application.register("click", ClickController)
application.register("menu", MenuController)
application.register("flash", FlashController)
application.register("map", MapController)
application.register("dialog", DialogController)
application.register("redirect", RedirectController)
application.register("columns", ColumnsController)
application.register("profiles", ProfilesController)
application.register("replace-content", ReplaceContentController)
application.register("entity-picker", EntityPickerController)
application.register("entities-selector", EntitiesSelectorController)
application.register("search", SearchController)
application.register("highlight", HighlightController)
application.register("property", PropertyController)
application.register("datepicker", DatepickerController)
application.register("toggle-secret", ToggleSecretController)
application.register("virtual-sources", VirtualSourcesController)
application.register("browser-viewports", BrowserViewportsController)
application.register("tree-menu", TreeMenuController)
application.register("columns-settings", ColumnsSettingsController)
application.register("elm", ElmController)
application.register("details", DetailsController)

application.handleError = (error, message, detail) => {
  console.warn(message, detail)
  Honeybadger.notify(error)
}

import '../../sonar/app/frontend/js/async.js'

import.meta.glob('./images/gfx')

// application.debug = true

import './javascript/trix'