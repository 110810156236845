import {Controller} from "@hotwired/stimulus"

/*
 * Entity picker controller handles a widget to choose an entity. The entity's
 * ID is set as an input's value. A pair of targets allows to represent
 * the currently selected entity or lack thereof.
 *
 * It is not intended for selecting multiple entities. For that purpose, use
 * EntitiesSelectorController.
 *
 * The controller can be combined with SearchController.
 *
 *  %div{data: { controller: 'entity-picker', entity_picker_hidden_class: 'hidden' }}
 *    %input{name: :entity_id, value: '616', data: { entity_picker_target: :input }}
 *
 *    %div{data: { entity_picker_target: :none },
 *        hidden: true,
 *        class: 'hidden'}
 *      This is shown when no entity is selected
 *    %div{data: { entity_picker_target: :selected,
 *                 action: 'click->entity-picker#unpick' },
 *        hidden: false}
 *        Entity 616
 *
 *    %dialog{data: { entity_picker_target: :dialog }}
 *      %button{data: { action: 'click->entity-picker#pick', entity_picker_id_param: '123' }}
 *        Entity 123
 *      %button{data: { action: 'click->entity-picker#pick', entity_picker_id_param: '42' }}
 *        Entity 42
 */
export default class extends Controller {
  static targets = ['input', 'dialog', 'none', 'selected']
  static classes = ['hidden']

  open() {
    this.dialogTarget.show();
    this.dialogTarget.querySelector('input').focus();
  }

  pick(e) {
    this.inputTarget.value = e.params.id;
    this.selectedTarget.innerHTML = e.currentTarget.innerHTML;

    this.selectedTarget.hidden = false;
    this.selectedTarget.classList.remove(this.hiddenClass);

    this.noneTarget.hidden = true;
    this.noneTarget.classList.add(this.hiddenClass);
  }

  unpick() {
    this.inputTarget.value = '';

    this.selectedTarget.hidden = true;
    this.selectedTarget.classList.add(this.hiddenClass);

    this.noneTarget.hidden = false;
    this.noneTarget.classList.remove(this.hiddenClass);
  }
}