import {Controller} from "@hotwired/stimulus"

/**
 * Areas controller handles update of current areas view when individual areas
 * are updated. The IA update happens in a dialog rendering an Elm component
 * because of its complexity. When the editing is done, Elm loads HTML snippets
 * with the current value of the edited individual area + current values of all
 * aggregates. The update method which is triggered by `areas:update` event on
 * the controlled element will:
 *
 * - replace each field of an area row (base area, occupied, ...) with new
 *   content or toggle the "not set" placeholder
 * - replace views of current aggregates
 * - close the dialog
 */
export default class extends Controller {
  static targets = ['individual', 'aggregate', 'membershipIndicator'];
  static classes = ['highlighted', 'unhighlighted']

  connect() {
    super.connect();
    this.updateHandler = this._update.bind(this);
    this.element.addEventListener('areas:update', this.updateHandler)
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('areas:update', this.updateHandler)
  }

  highlightIndividuals(e) {
    this._toggleIndividual(e, this.highlightedClass, this.unhighlightedClass);
  }

  unhighlightIndividuals(e) {
    this._toggleIndividual(e, this.unhighlightedClass, this.highlightedClass);
  }

  highlightAggregate(e) {
    this._toggleAggregate(e, this.highlightedClass, this.unhighlightedClass)
  }

  unhighlightAggregate(e) {
    this._toggleAggregate(e, this.unhighlightedClass, this.highlightedClass);
  }

  _toggleAggregate(e, toAdd, toRemove) {
    e.currentTarget.dataset.areasAggregateMemberships.split(' ').forEach(aggregate => {
      this.aggregateTargets.forEach(el => {
        el = el.querySelector('[data-areas-target="membershipIndicator"]');
        if (el.dataset.areasAggregate === aggregate) {
          el.classList.add(toAdd);
          el.classList.remove(toRemove);
        }
      })
    })
  }

  _toggleIndividual(e, toAdd, toRemove) {
    const aggregate = e.currentTarget.dataset.areasAggregate;
    this.individualTargets.forEach(el => {
      el = el.querySelector('[data-areas-target="membershipIndicator"]');
      if (el.dataset.areasAggregateMemberships.includes(aggregate)) {
        el.classList.add(toAdd);
        el.classList.remove(toRemove);
      }
    })
  }

  _update(e) {
    if (e.detail) {
      this._updateIndividualArea(e.detail.area_type_id, e.detail.current_values.individual_area);
      this._updateAggregates(e.detail.current_values);
    }
  }

  _updateIndividualArea(areaTypeId, individualArea) {
    const individual = this.individualTargets.find(individual => {
      return individual.dataset.areasAreaTypeId === areaTypeId;
    })
    if (!individual) {
      return;
    }

    if (individualArea && Object.keys(individualArea).length > 0) {
      individual.querySelectorAll('[data-areas-replace]').forEach((cell) => {
        cell.innerHTML = individualArea[cell.dataset.areasReplace];
        cell.hidden = false;
      })
      individual.querySelectorAll('.js-not-set').forEach(e => e.hidden = true)
    } else {
      individual.querySelectorAll('[data-areas-replace]').forEach(cell => {
        cell.innerHTML = individualArea[cell.dataset.areasReplace];
        cell.hidden = true;
      })
      individual.querySelectorAll('.js-not-set').forEach(e => e.hidden = false)
    }
  }

  _updateAggregates(data) {
    this.aggregateTargets.forEach(agg => {
      const d = data[agg.dataset.areasAggregate];
      if (d) {
        agg.outerHTML = d;
      }
    })
  }
}