import {Controller} from "@hotwired/stimulus"

/**
 * input[type=range] does not show the value it has selected in any reasonable
 * way. This controller grabs the value and renders it, as well as
 * the complement.
 *
 * It assumes an integer value with minimum = 0.
 *
 *  %div{data: { controller: 'range' }}
 *    %input{type: :range, max: 100, step: 1, data: { action: 'change->range#change' }}
 *    %div
 *      Value:
 *      %div{data: { range_target: 'left'}} 30
 *      %
 *    %div
 *      Complement
 *      %div{data: { range_target: 'right'}} 70
 *      %
 */
export default class extends Controller {
  static targets = ['left', 'right']

  connect() {
    this.element.querySelectorAll('[data-action]').forEach(el => {
      if (el.dataset['action'].includes(`->${this.context.module.identifier}#change`)) {
        this.change({target: el})
      }
    })
  }

  change(e) {
    const el = e.target;
    this.leftTarget.textContent = el.value;
    this.rightTarget.textContent = parseInt(el.max) - el.value;
  }
}