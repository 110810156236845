import {Controller} from '@hotwired/stimulus'
import IsoDateTime from './iso_date_time.js'
import {format} from "elm-review/lib/path-helpers";

/**
 * Vendored from https://github.com/airblade/stimulus-datepicker
 * This is a Stimulus-powered datepicker which -
 * - localises and parses dates in the input field
 * - presents a calendar
 * - sends the date back to the server in localised format supplied in format data attribute/ or ISO format.
 * - All dates are local, not UTC.
 *
 *  .flex.flex-row.items-center{data: { controller: :datepicker ,
 *                              datepicker_format_value: "%Y-%m-%d" } }
 *     %input{ data: { datepicker_target: 'input' }, value: '2024-12-09' }
 *     %button.w-4.h-4.-ml-6{ data: { datepicker_target: 'toggle' }, type: :button }
 *      open datepicker
 *
 * Known issue - When min/max values are supplied, while the calendar dialog greys out non-applicable date,
 * they can still be typed and entered
 */
export default class Datepicker extends Controller {

  static targets = ['input', 'hidden', 'toggle', 'calendar', 'month', 'year',
    'prevMonth', 'today', 'nextMonth', 'days', 'hours', 'minutes', 'predefinedRange']

  static values = {
    dateTime: String,
    dateRange: String,
    min: String,
    max: String,
    dateFormat: {type: String, default: 'yyyy-MM-dd'},
    timeFormat: String,
    firstDayOfWeek: {type: Number, default: 1},
    dayNameLength: {type: Number, default: 2},
    monthJump: {type: String, default: 'dayOfMonth'},
    disallow: Array,
    locale: {type: String, default: 'default'},
    time: {type: Boolean, default: false},
    range: {type: Boolean, default: false}
  }

  static classes = ['current']

  text(key) {
    // force i18n key extraction: I18n.t('date_picker.')
    return window.I18n.t(`date_picker.${key}`);
  }

  connect() {
    this.connected = true
    this.addInputAction()
    this.addToggleAction()
    this.setToggleAriaLabel()
    if (this.timeValue) this.dateTimeValue = this.parseDateTime(this.inputTarget.value)
    if (this.rangeValue) this.dateRangeValue = this.formatRange(this.parseDateRange(this.inputTarget.value))
  }

  // this method is automatically used by https://stimulus.hotwired.dev/reference/values#change-callbacks
  dateRangeValueChanged(value, previousValue) {
    if (!this.connected) return // weirdly, this can be triggered before connect
    if (!value) {
      this.inputTarget.value = ''
      return
    }

    const range = this.parseDateRange(value)
    if (!range.to) {
      return
    }

    const formattedRange = this.formatRange(range)
    if (this.inputTarget.value !== formattedRange) {
      this.inputTarget.value = formattedRange

      // Trigger change event on input when user selects date from picker.
      // http://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
      this.inputTarget.dispatchEvent(new Event('change'))
    }

    this.validate(range.from)
    this.validate(range.to)
    const ev = new CustomEvent('datepicker:date-range-picked', {
      bubbles: false,
      detail: {
        from: range.from.toDateString(),
        to: range.to.toDateString()
      }
    });
    this.element.dispatchEvent(ev);
  }

  highlightPredefinedRangesWhenSelected() {
    if (!this.rangeValue) {
      return
    }

    this.predefinedRangeTargets.forEach(target => {
      if (target.dataset.value === this.dateRangeValue) {
        target.classList.add('text-blue-500')
      } else {
        target.classList.remove('text-blue-500')
      }
    })
  }

  highlightRangeInCalendarWhenDateRangeSelected() {
    if (!this.rangeValue) {
      return
    }

    const parsedDateRange = this.parseDateRange(this.dateRangeValue)
    const dateInSelectedRangeClasses = ['bg-blue-200', 'rounded-full']
    this.daysTarget.querySelectorAll('button').forEach(button => {
      const buttonDate =
        new IsoDateTime(button.querySelector('time').getAttribute('datetime')).setHour(0).setMinute(0)
      if ((parsedDateRange.from && buttonDate.equalsDate(parsedDateRange.from)) ||
        (parsedDateRange.to && buttonDate.equalsDate(parsedDateRange.to))) {
        button.setAttribute('tabindex', 0)
        button.setAttribute('aria-selected', true)
        button.classList.add(this.currentClass)
      } else if (parsedDateRange.from &&
        parsedDateRange.to &&
        buttonDate.after(parsedDateRange.from) &&
        buttonDate.before(parsedDateRange.to)) {
        button.classList.add(...dateInSelectedRangeClasses)
      } else {
        button.setAttribute('tabindex', -1)
        button.removeAttribute('aria-selected')
        button.classList.remove(this.currentClass, ...dateInSelectedRangeClasses)
      }
    })
  }

  // this method is automatically used by https://stimulus.hotwired.dev/reference/values#change-callbacks
  dateTimeValueChanged(value, previousValue) {
    if (!this.connected) return // weirdly, this can be triggered before connect
    if (!value) {
      this.inputTarget.value = ''
      return
    }

    const isoDateTime = this.clamp(new IsoDateTime(value));
    this.inputTarget.value = this.format(isoDateTime)
    // Trigger change event on input when user selects date from picker.
    // http://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/change_event
    if (value != previousValue) this.inputTarget.dispatchEvent(new Event('change'))
    let detail = {}
    if (this.timeValue) {
      detail.datetime = isoDateTime.toString() + ':00' + isoDateTime.toDate().getUTCOffset();
    } else {
      detail.date = isoDateTime.toDateString();
    }
    const ev = new CustomEvent('datepicker:date-picked', {
      bubbles: false,
      detail: detail
    });
    this.element.dispatchEvent(ev);
  }

  validate(dateStr) {
    const message = this.validationMessage(dateStr)
    this.inputTarget.setCustomValidity(message)
    if (message) this.inputTarget.reportValidity()
  }

  validationMessage(dateStr) {
    if (!dateStr) return ''
    const isoDateTime = new IsoDateTime(dateStr)
    return this.rangeUnderflow(isoDateTime) ? this.underflowMessage()
      : this.rangeOverflow(isoDateTime) ? this.overflowMessage()
        : ''
  }

  underflowMessage() {
    return this.text('underflow').replace('%s', this.format(new IsoDateTime(this.minValue)))
  }

  overflowMessage() {
    return this.text('overflow').replace('%s', this.format(new IsoDateTime(this.maxValue)))
  }

  addInputAction() {
    this.addAction(this.inputTarget, 'datepicker#update')
  }

  addToggleAction() {
    if (!this.hasToggleTarget) return

    let action = 'click->datepicker#toggle'
    if (!(this.toggleTarget instanceof HTMLButtonElement)) action += ' keydown->datepicker#toggle'

    this.addAction(this.toggleTarget, action)
  }

  addAction(element, action) {
    if (('action') in element.dataset) {
      element.dataset.action += ` ${action}`
    } else {
      element.dataset.action = action
    }
  }

  setToggleAriaLabel(value = this.text('choose_date')) {
    if (!this.hasToggleTarget) return
    this.toggleTarget.setAttribute('aria-label', value);
  }

  update() {
    if (this.rangeValue) {
      this.dateRangeValue = this.inputTarget.value
    } else {
      const dateStr = this.parseDateTime(this.inputTarget.value)
      if (dateStr != '') this.dateTimeValue = dateStr
    }
  }

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.inputTarget.disabled) return
    if (event.type == 'keydown' && ![' ', 'Enter'].includes(event.key)) return
    this.hasCalendarTarget ? this.close(true) : this.open(true)
  }

  close(animate = true) {
    if (animate) {
      this.calendarTarget.onanimationend = e => e.target.remove()
      this.calendarTarget.classList.add('animate-fade-out')
    } else {
      this.calendarTarget.remove()
    }

    this.toggleTarget.focus()
  }

  open(animate, isoDateTimes = this.initialIsoDateTimes()) {
    this.render(isoDateTimes)
    if (!this.rangeValue) {
      this.focusDate(isoDateTimes.at(-1))
    }
    this.highlightPredefinedRangesWhenSelected()
    this.highlightRangeInCalendarWhenDateRangeSelected()
  }

  // Returns the date time to focus on initially.  This is `dateTimeValue` if given
  // or today.  Whichever is used, it is clamped to `minValue` and/or `maxValue`
  // dates if given.
  initialIsoDateTimes() {
    if (this.rangeValue) {
      const range = this.parseDateRange(this.dateRangeValue)
      if (range.from && range.to) {
        return [this.clamp(new IsoDateTime(range.from)), this.clamp(new IsoDateTime(range.to))]
      } else if (range.from) {
        return [this.clamp(new IsoDateTime(range.from))]
      } else {
        return [this.clamp(new IsoDateTime())]
      }
    } else {
      return [this.clamp(new IsoDateTime(this.dateTimeValue))]
    }
  }

  clamp(isoDateTime) {
    return this.rangeUnderflow(isoDateTime) ? new IsoDateTime(this.minValue)
      : this.rangeOverflow(isoDateTime) ? new IsoDateTime(this.maxValue)
        : isoDateTime
  }

  rangeUnderflow(isoDateTime) {
    return this.hasMinValue && isoDateTime.before(new IsoDateTime(this.minValue))
  }

  rangeOverflow(isoDateTime) {
    return this.hasMaxValue && isoDateTime.after(new IsoDateTime(this.maxValue))
  }

  isOutOfRange(isoDateTime) {
    return this.rangeUnderflow(isoDateTime) || this.rangeOverflow(isoDateTime)
  }

  closeOnOutsideClick(event) {
    // `event.target` could already have been removed from the DOM
    // (e.g. if the previous-month button was clicked) so we cannot
    // use `this.calendarTarget.contains(event.target)`.
    if (event.target.closest('[data-datepicker-target="calendar"]')) return
    if (event.target.closest('[data-datepicker-target="input"]')) return
    this.close(true)
  }

  redraw() {
    const isoDateTime = this.dateFromMonthYearSelectsAndDayGrid()
    this.close(false)
    this.open(false, [isoDateTime])
  }

  gotoPrevMonth(event) {
    event.preventDefault()
    const isoDateTime = this.dateFromMonthYearSelectsAndDayGrid()
    this.close(false)
    this.open(false, [isoDateTime.previousMonth(this.monthJumpValue == 'dayOfMonth')])
    this.prevMonthTarget.focus()
  }

  gotoNextMonth(event) {
    event.preventDefault()
    const isoDateTime = this.dateFromMonthYearSelectsAndDayGrid()
    this.close(false)
    this.open(false, [isoDateTime.nextMonth(this.monthJumpValue == 'dayOfMonth')])
    this.nextMonthTarget.focus()
  }

  gotoToday(event) {
    event.preventDefault()
    this.close(false)
    let today = new IsoDateTime();
    if (this.timeValue) {
      const current = new IsoDateTime(this.dateTimeValue)
      today = today.setHour(current.hh).setMinute(current.min)
    }
    this.open(false, [today])
    this.todayTarget.focus()
  }

  // Returns a date where the month and year come from the dropdowns
  // and the day of the month from the grid. Defaults to the first if
  // no day in the current month is selected.
  // @return [IsoDateTime]
  dateFromMonthYearSelectsAndDayGrid() {
    const year = this.yearTarget.value
    const month = this.monthTarget.value
    const selectedDay = this.daysTarget.querySelector('button[tabindex="0"] time');
    let day
    if (selectedDay) {
      day = selectedDay.textContent;
    } else {
      day = 1
    }

    const daysInMonth = IsoDateTime.daysInMonth(+month, +year)
    if (day > daysInMonth) day = daysInMonth

    let hour = 0
    let minute = 0
    if (this.timeValue) {
      hour = this.hoursTarget.querySelector('button[tabindex="0"]').textContent
      minute = this.minutesTarget.querySelector('button[tabindex="0"]').textContent
    }

    return new IsoDateTime(year, month, day, hour, minute)
  }


  // Generates the HTML for the calendar and inserts it into the DOM.
  //
  // Does not focus the given date.
  //
  // @param isoDateTime [IsoDateTime] the date of interest
  render(isoDateTimes) {
    const cal = `
      <div class="top-full absolute z-30 box-border w-content border border-solid border-grey-300 rounded-md bg-white shadow-md p-2 flex flex-row gap-2 divide-x divide-grey-500 ${this.alignClass()}" data-datepicker-target="calendar" data-action="click@window->datepicker#closeOnOutsideClick keydown->datepicker#key" role="dialog" aria-modal="true" aria-label="${this.text('choose_date')}">
        ${this.predefinedRanges()}
        <div class="space-y-2 pl-2">
          <div class="flex justify-between items-center gap-4">
            <div class="flex flex-row gap-4">
              <select class="datepicker-select bg-white text-current p-1 pl-0 pr-1 cursor-pointer box-content" data-datepicker-target="month" data-action="datepicker#redraw">
                ${this.monthOptions(+isoDateTimes.at(-1).mm)}
              </select>
              <select class="datepicker-select bg-white text-current p-1 pl-0 pr-1 cursor-pointer box-content" data-datepicker-target="year" data-action="datepicker#redraw">
                ${this.yearOptions(+isoDateTimes.at(-1).yyyy)}
              </select>
            </div>
            <div class="flex gap-2">
              <button class="${this.buttonClasses()}" data-datepicker-target="prevMonth" data-action="datepicker#gotoPrevMonth" title="${this.text('previous_month')}" aria-label="${this.text('previous_month')}">
                <svg viewBox="0 0 10 10" class="fill-none stroke-current stroke-2 w-3 h-3">
                  <polyline points="7,1 3,5 7,9" />
                </svg>
              </button>
              <button class="${this.buttonClasses()}" data-datepicker-target="today" data-action="datepicker#gotoToday" title="${this.text('today')}" aria-label="${this.text('today')}">
                <svg viewBox="0 0 10 10" class="fill-none stroke-current stroke-2 w-3 h-3">
                  <circle cx="5" cy="5" r="4" />
                </svg>
              </button>
              <button class="${this.buttonClasses()}" data-datepicker-target="nextMonth" data-action="datepicker#gotoNextMonth" title="${this.text('next_month')}" aria-label="${this.text('next_month')}">
                <svg viewBox="0 0 10 10" class="fill-none stroke-current stroke-2 w-3 h-3">
                  <polyline points="3,1 7,5 3,9" />
                </svg>
              </button>
            </div>
          </div>
          <div class="grid grid-calendar justify-between gap-2 text-center uppercase text-xs text-grey-600 font-semibold">
            ${this.daysOfWeek()}
          </div>
          <div class="grid grid-calendar justify-between gap-2 text-center" data-datepicker-target="days" data-action="click->datepicker#pickDate" role="grid">
            ${this.days(isoDateTimes)}
          </div>
          ${this.doneButton()}
        </div>
        ${this.renderTimePicker(isoDateTimes.at(-1))}
      </div>
    `
    this.element.insertAdjacentHTML('beforeend', cal)
  }

  // aligns the calendar to the left if the controlled element is closer to
  // the left side of dialog/window, to the right otherwise
  alignClass() {
    const inputBoundingBox = this.inputTarget.getBoundingClientRect()
    let context = this.element.closest('dialog')
    if (!context) {
      context = document.body
    }
    const contextBoundingBox = context.getBoundingClientRect()
    const inputCenter = inputBoundingBox.left + inputBoundingBox.width / 2
    const contextCenter = contextBoundingBox.left + contextBoundingBox.width / 2

    if (inputCenter < contextCenter) {
      return 'left-0'
    } else {
      return 'right-0'
    }
  }

  buttonClasses() {
    return 'flex items-center justify-center rounded-full w-6 h-6 ' +
      'text-grey-600 bg-grey-200 cursor-pointer ' +
      'hover:bg-grey-400 focus:bg-grey-400';
  }

  doneButton() {
    if (!this.timeValue && !this.rangeValue) {
      return ''
    }

    return `<button type="button" 
                    data-action="click->datepicker#close"
                    class="submit-button text-xs float-right">${window.I18n.t('daterange_js_done')}</button>`
  }

  renderTimePicker(isoDateTime) {
    if (!this.timeValue) {
      return '';
    }

    return `
      <div class="pl-2 flex flex-row gap-2">
        <ul class="overflow-auto max-h-96 thin-scrollbar px-1 space-y-px"
            data-action="click->datepicker#pickHour"
            data-datepicker-target="hours">${this.hoursOfDay(isoDateTime)}</ul>
        <ul class="overflow-auto max-h-96 thin-scrollbar px-1 space-y-px"
            data-action="click->datepicker#pickMinute"
            data-datepicker-target="minutes">${this.minutesOfHour(isoDateTime)}</ul>
      </div>
    `
  }

  hoursOfDay(isoDateTime) {
    const current = isoDateTime.toDate().getHours()
    let hours = []
    for (let i = 0; i < 24; i++) {
      hours.push(this.timeItem(isoDateTime.setHour(i).hh, current))
    }
    return hours.join('')
  }

  minutesOfHour(isoDateTime) {
    const current = isoDateTime.toDate().getMinutes()
    let minutes = []
    for (let i = 0; i < 60; i++) {
      minutes.push(this.timeItem(isoDateTime.setMinute(i).min, current))
    }
    return minutes.join('')
  }

  timeItem(str, currentValue) {
    const isCurrent = +str === currentValue
    return `
        <li ${this.classAttribute(isCurrent ? this.currentClass : '')}>
            <button type="button" 
                    tabindex="${isCurrent ? 0 : -1}" 
                    ${isCurrent ? 'aria-selected="true"' : ''}
                    class="text-center py-1 px-2 rounded current:bg-blue-500 current:text-white hover:bg-blue-600 focus:bg-blue-600 cursor-pointer hover:text-white focus:text-white w-full">${str}</button>
        </li>
    `
  }

  monthTargetConnected() {
    this.autoSizeSelect(this.monthTarget)
  }

  yearTargetConnected() {
    this.autoSizeSelect(this.yearTarget)
  }

  // Set select's width to the width of the selected option.
  autoSizeSelect(select) {
    const tempSelect = document.createElement('select')
    const tempOption = document.createElement('option')
    tempOption.textContent = select.options[select.selectedIndex].text
    tempSelect.style.cssText += 'visibility: hidden; position: fixed;'
    tempSelect.appendChild(tempOption)
    select.after(tempSelect)
    const tempSelectWidth = tempSelect.getBoundingClientRect().width
    select.style.width = `${tempSelectWidth}px`
    tempSelect.remove()
  }

  pickDate(event) {
    event.preventDefault()

    let button, time
    switch (event.target.constructor) {
      case HTMLTimeElement:
        time = event.target
        button = time.parentElement
        break
      case HTMLButtonElement:
        button = event.target
        time = button.children[0]
        break
      default:
        return
    }

    if (button.hasAttribute('aria-disabled')) return

    const oldValue = new IsoDateTime(this.dateTimeValue)
    let isoDateTime = new IsoDateTime(time.getAttribute('datetime'));
    if (oldValue) {
      isoDateTime = isoDateTime.syncTime(oldValue)
    } else {
      isoDateTime = isoDateTime.setHour(0).setMinute(0)
    }
    this.selectDate(isoDateTime)
  }

  pickHour(event) {
    event.preventDefault()

    if (event.target.tagName !== 'BUTTON') return

    this.selectHour(event.target);
  }

  selectHour(button) {
    this.dateTimeValue = new IsoDateTime(this.dateTimeValue).setHour(button.textContent).toString()
    this.hoursTarget.querySelectorAll('li').forEach(li => li.classList.remove(this.currentClass))
    this.hoursTarget.querySelectorAll('button').forEach(b => b.tabIndex = -1)
    button.parentElement.classList.add(this.currentClass)
    button.tabIndex = 0
  }

  pickMinute(event) {
    event.preventDefault()

    if (event.target.tagName !== 'BUTTON') return

    this.selectMinute(event.target);
  }

  selectMinute(button) {
    this.dateTimeValue = new IsoDateTime(this.dateTimeValue).setMinute(button.textContent).toString()
    this.minutesTarget.querySelectorAll('li').forEach(li => li.classList.remove(this.currentClass))
    this.minutesTarget.querySelectorAll('button').forEach(b => b.tabIndex = -1)
    button.parentElement.classList.add(this.currentClass)
    button.tabIndex = 0
  }

  key(event) {
    switch (event.key) {
      case 'Escape':
        this.close(true)
        return
      case 'Tab':
        if (event.shiftKey) {
          if (document.activeElement == this.firstTabStop()) {
            event.preventDefault()
            this.lastTabStop().focus()
          }
        } else {
          if (document.activeElement == this.lastTabStop()) {
            event.preventDefault()
            this.firstTabStop().focus()
          }
        }
        return
    }

    const button = event.target
    if (this.daysTarget.contains(button)) {
      this.dayKey(event, button)
    } else if (this.hoursTarget.contains(button)) {
      this.hoursKey(event, button)
    } else if (this.minutesTarget.contains(button)) {
      this.minutesKey(event, button)
    }
  }

  dayKey(event, button) {
    const dateStr = button.children[0].getAttribute('datetime')
    const isoDateTime = new IsoDateTime(dateStr)

    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        if (!button.hasAttribute('aria-disabled')) this.selectDate(isoDateTime)
        break
      case 'ArrowUp':
      case 'k':
        this.focusDate(isoDateTime.previousWeek())
        break
      case 'ArrowDown':
      case 'j':
        this.focusDate(isoDateTime.nextWeek())
        break
      case 'ArrowLeft':
      case 'h':
        this.focusDate(isoDateTime.previousDay())
        break
      case 'ArrowRight':
      case 'l':
        this.focusDate(isoDateTime.nextDay())
        break
      case 'Home':
      case '0':
      case '^':
        this.focusDate(isoDateTime.firstDayOfWeek(this.firstDayOfWeekValue))
        break
      case 'End':
      case '$':
        this.focusDate(isoDateTime.lastDayOfWeek(this.firstDayOfWeekValue))
        break
      case 'PageUp':
        event.shiftKey
          ? this.focusDate(isoDateTime.previousYear())
          : this.focusDate(isoDateTime.previousMonth(this.monthJumpIsDayOfMonth()))
        break
      case 'PageDown':
        event.shiftKey
          ? this.focusDate(isoDateTime.nextYear())
          : this.focusDate(isoDateTime.nextMonth(this.monthJumpIsDayOfMonth()))
        break
      case 'b':
        this.focusDate(isoDateTime.previousMonth(this.monthJumpIsDayOfMonth()))
        break
      case 'B':
        this.focusDate(isoDateTime.previousYear())
        break
      case 'w':
        this.focusDate(isoDateTime.nextMonth(this.monthJumpIsDayOfMonth()))
        break
      case 'W':
        this.focusDate(isoDateTime.nextYear())
        break
    }
  }

  hoursKey(event, button) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        this.selectHour(button)
        break
      case 'ArrowUp':
      case 'k':
        this.focusPreviousTimeItem(button);
        break
      case 'ArrowDown':
      case 'j':
        this.focusNextTimeItem(button);
        break
    }
  }

  minutesKey(event, button) {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        this.selectMinute(button)
        break
      case 'ArrowUp':
      case 'k':
        this.focusPreviousTimeItem(button)
        break
      case 'ArrowDown':
      case 'j':
        this.focusNextTimeItem(button);
        break
    }
  }

  focusPreviousTimeItem(button) {
    button.setAttribute('tabindex', -1)

    const previousListItem = button.parentElement.previousElementSibling;
    if (!previousListItem) return

    const prev = previousListItem.querySelector('button')
    prev.setAttribute('tabindex', 0)
    prev.focus()
  }

  focusNextTimeItem(button) {
    button.setAttribute('tabindex', -1)

    const nextListItem = button.parentElement.nextElementSibling;
    if (!nextListItem) return

    const next = nextListItem.querySelector('button')
    next.setAttribute('tabindex', 0)
    next.focus()
  }

  firstTabStop() {
    return this.monthTarget
  }

  lastTabStop() {
    const buttons = this.calendarTarget.querySelectorAll('button[tabindex="0"]');
    return buttons[buttons.length - 1]
  }

  monthJumpIsDayOfMonth() {
    return this.monthJumpValue == 'dayOfMonth'
  }


  // @param isoDateTime [IsoDateTime] the date to select
  selectDate(isoDateTime) {
    if (this.timeValue) {
      this.daysTarget.querySelectorAll('button').forEach(button => {
        const buttonDate = new IsoDateTime(button.querySelector('time').getAttribute('datetime'))
        if (buttonDate.equalsDate(isoDateTime)) {
          button.setAttribute('tabindex', 0)
          button.setAttribute('aria-selected', true)
          button.focus()
          button.classList.add(this.currentClass)
        } else {
          button.setAttribute('tabindex', -1)
          button.removeAttribute('aria-selected')
          button.classList.remove(this.currentClass)
        }
      })
      this.dateTimeValue = isoDateTime.toString()
    } else if (this.rangeValue) {
      const currentlySelectedRange = this.parseDateRange(this.dateRangeValue)
      if (!currentlySelectedRange.from || (currentlySelectedRange.from && currentlySelectedRange.to)) {
        this.dateRangeValue = this.formatRange({from: isoDateTime})
      } else {
        this.dateRangeValue = this.formatRange({from: currentlySelectedRange.from, to: isoDateTime})
        this.toggleTarget.focus()
      }

      this.highlightRangeInCalendarWhenDateRangeSelected()
      this.highlightPredefinedRangesWhenSelected()
    } else {
      this.close(true)
      this.toggleTarget.focus()
      this.dateTimeValue = isoDateTime.toDateString()
    }
  }

  // Focuses the given date in the calendar.
  // If the date is not visible because it is in the hidden part of the previous or
  // next month, the calendar is updated to show the corresponding month.
  //
  // @param isoDateTime [IsoDateTime] the date to focus on in the calendar
  focusDate(isoDateTime) {
    const time = this.daysTarget.querySelector(`time[datetime="${isoDateTime.toDateString()}"]`)

    if (!time) {
      const leadingDatetime = this.daysTarget.querySelector('time').getAttribute('datetime')
      if (isoDateTime.before(new IsoDateTime(leadingDatetime))) {
        this.gotoPrevMonth()
      } else {
        this.gotoNextMonth()
      }
      this.focusDate(isoDateTime)
      return
    }

    const currentFocus = this.daysTarget.querySelector('button[tabindex="0"]')
    if (currentFocus) currentFocus.setAttribute('tabindex', -1)

    const button = time.parentElement
    button.setAttribute('tabindex', 0)
    button.focus()

    if (!button.hasAttribute('aria-disabled')) {
      this.setToggleAriaLabel(`${this.text('change_date')}, ${this.format(isoDateTime)}`)
    }
  }

  predefinedRanges() {
    if (!this.rangeValue) {
      return ''
    }

    const buttonClasses = 'w-full p-1 text-left text-xs text-grey-600 font-semibold hover:text-blue-500 focus:text-blue-500'

    const today = new IsoDateTime();

    // Define top-level constants
    const ranges = [
        {
            key: 'todayRange',
            range: { from: today, to: today },
            label: this.text('today'),
        },
        {
            key: 'lastSevenDaysRange',
            range: { from: today.previousWeek().nextDay(), to: today },
            label: window.I18n.t('daterange_js_last_7_days'),
        },
        {
            key: 'lastTwentyEightDaysRange',
            range: { from: today.previousWeek().previousWeek().previousWeek().previousWeek().nextDay(), to: today },
            label: window.I18n.t('daterange_js_last_28_days'),
        },
        {
            key: 'lastThreeMonthsRange',
            range: { from: today.previousMonth().previousMonth().previousMonth(), to: today },
            label: window.I18n.t('daterange_js_last_3_months'),
        },
        {
            key: 'lastTwelveMonthsRange',
            range: { from: today.previousYear(), to: today },
            label: window.I18n.t('daterange_js_last_12_months'),
        },
        {
            key: 'thisYearRange',
            range: {
                from: (() => {
                    const firstJanuary = today.setDayOfMonth(1);
                    firstJanuary.mm = '01';
                    return firstJanuary;
                })(),
                to: today,
            },
            label: this.text('this_year'),
        },
    ];

    // Generate ranges and classes dynamically
    const buttonHtml = ranges.map(({ key, range, label }) => {
        const formattedRange = this.formatRange(range);
        let classes = buttonClasses;
        if (this.dateRangeValue === formattedRange) {
            classes += ' text-blue-500';
        }

        return `
          <button type="button" 
                  data-datepicker-target="predefinedRange" 
                  class="${classes}" 
                  title="${formattedRange}" 
                  data-value="${formattedRange}" 
                  data-action="click->datepicker#pickPredefinedRange">
            ${label}
          </button>
        `;
    }).join('');

    // Return the final HTML
        return `
    <div class="flex flex-col">
      ${buttonHtml}
    </div>
  `;

  }

  pickPredefinedRange(event) {
    event.preventDefault()
    this.selectDateRange(event.target.dataset.value)

    const range = this.parseDateRange(this.dateRangeValue)
    if (range.from) {
      this.close(false)
      this.open(false, [range.from])
    }
    this.highlightRangeInCalendarWhenDateRangeSelected()
    this.highlightPredefinedRangesWhenSelected()
  }

  selectDateRange(range) {
    this.toggleTarget.focus()
    this.dateRangeValue = range
  }

  // @param selected [Number] the selected month (January is 1)
  monthOptions(selected) {
    return this.monthNames('long')
      .map((name, i) => `<option value="${i + 1}" ${i + 1 == selected ? 'selected' : ''}>${name}</option>`)
      .join('')
  }

  // @param selected [Number] the selected year
  yearOptions(selected) {
    const years = []
    const extent = 10
    for (let y = selected - extent; y <= selected + extent; y++) years.push(y)
    return years
      .map(year => `<option ${year == selected ? 'selected' : ''}>${year}</option>`)
      .join('')
  }

  daysOfWeek() {
    return this.dayNames('long')
      .map(name => `<div title="${name}" class="datepicker-button inline-flex items-center justify-center">${name.slice(0, this.dayNameLengthValue)}</div>`)
      .join('')
  }

  // Generates the day grid for the given date's month.
  // The end of the previous month and the start of the next month
  // are shown if there is space in the grid.
  //
  // Does not focus on the given date.
  //
  // @param isoDateTime [IsoDateTime] the month of interest
  // @return [String] HTML for the day grid
  days(isoDateTimes) {
    const days = []
    let selected = []
    if (this.rangeValue) {
      const range = this.parseDateRange(this.dateRangeValue)

      if (range.from) selected.push(range.from)
      if (range.to) selected.push(range.to)
    } else {
      selected.push(new IsoDateTime(this.dateTimeValue))
    }

    let dateTime = isoDateTimes.at(-1).setDayOfMonth(1).firstDayOfWeek(this.firstDayOfWeekValue)

    while (true) {
      const isoDateTime = isoDateTimes.at(-1)
      const isPreviousMonth = dateTime.mm != isoDateTime.mm && dateTime.before(isoDateTime)
      const isNextMonth = dateTime.mm != isoDateTime.mm && dateTime.after(isoDateTime)

      if (isNextMonth && dateTime.isFirstDayOfWeek(this.firstDayOfWeekValue)) break

      let borderColor = '', bgColor = '', outline = '';
      if (this.isOutOfRange(dateTime)) {
        bgColor = 'current:bg-grey-500 current:group-focus:bg-grey-600 group-hover:bg-grey-600 group-focus:bg-grey-600';
        if (dateTime.isToday()) {
          borderColor = 'border-grey-500 group-hover:border-white group-focus:border-white';
        } else {
          borderColor = 'border-transparent group-hover:border-grey-600 group-focus:border-grey-600';
        }
      } else {
        bgColor = 'current:bg-blue-500 current:group-focus:bg-blue-600 group-hover:bg-blue-600 group-focus:bg-blue-600 group-hover:text-white'
        if (dateTime.isToday()) {
          borderColor = 'border-blue-500 group-hover:border-blue-500 group-focus:border-blue-500 border-2 border-solid';
        } else {
          borderColor = 'border-transparent group-hover:border-blue-600 group-focus:border-blue-600';
        }
      }

      const klass = this.classAttribute(
        'flex justify-center items-center leading-none',
        'rounded-full datepicker-button',
        borderColor,
        bgColor,
        'current:text-white group-hover:text-white group-focus:text-white',
        outline,
        this.isDisabled(dateTime) ? 'text-grey-700 cursor-not-allowed' : 'cursor-pointer text-current',
        isPreviousMonth ? 'text-grey-500' : '',
        isNextMonth ? 'text-grey-500' : '',
      )
      let dateTimeIsSelected = false
      selected.forEach(sel => {
        if (dateTimeIsSelected) return
        if (sel.equalsDate(dateTime)) {
          dateTimeIsSelected = true
        }
      })

      const buttonClass = this.classAttribute(
        dateTimeIsSelected ? this.currentClass : '',
        'focus:outline-none group',
      )
      days.push(`
        <button type="button"
                tabindex="-1"
                ${buttonClass}
                ${dateTimeIsSelected ? 'aria-selected="true"' : ''}
                ${this.isDisabled(dateTime) ? 'aria-disabled="true"' : ''}
        >
          <time datetime="${dateTime.toDateString()}"
                ${klass}>${+dateTime.dd}</time>
        </button>
      `)

      dateTime = dateTime.nextDay()
    }

    return days.join('')
  }

  classAttribute(...classes) {
    const presentClasses = classes.filter(c => c)
    if (presentClasses.length == 0) return ''
    return `class="${presentClasses.join(' ')}"`
  }

  isDisabled(isoDateTime) {
    return this.isOutOfRange(isoDateTime)
      || (this.disallowValue.includes(isoDateTime.toString()))
  }

  // Formats an IsoDateTime, using the `format` value, for display to the user.
  // Returns an empty string if `str` cannot be formatted.
  //
  // @param str [IsoDateTime] a date time
  // @return [String] the date in a user-facing format, or an empty string if the
  //   given date cannot be formatted
  format(isoDateTime) {
    if (this.timeValue) {
      return isoDateTime.toDate().toString(this.fullFormat())
    } else {
      return isoDateTime.toDate().toString(this.dateFormatValue)
    }
  }

  formatRange(range) {
    if (range.from === undefined && range.to === undefined) return ''
    if (range.to === undefined) return `${this.format(range.from)} - `
    if (range.from.after(range.to)) return `${this.format(range.to)} - ${this.format(range.from)}`

    return `${this.format(range.from)} - ${this.format(range.to)}`
  }

  // Returns a two-digit zero-padded string.
  zeroPad(num) {
    return num.toString().padStart(2, '0')
  }

  // Parses a date from the user, using the `format` value, into an ISO8601 date.
  // Returns an empty string if `str` cannot be parsed.
  //
  // @param str [String] a user-facing date, e.g. 19/03/2022
  // @return [String] the date in ISO8601 format, e.g. 2022-03-19; or an empty string
  //   if the given date cannot be parsed
  parseDateTime(str) {
    if (str === '') {
      return ''
    }

    const date = Date.parseExact(str, this.fullFormat())
    if (date === null) {
      return ''
    }

    return new IsoDateTime(
      date.getFullYear().toString(),
      this.zeroPad(date.getMonth() + 1),
      this.zeroPad(date.getDate()),
      this.zeroPad(date.getHours()),
      this.zeroPad(date.getMinutes())
    )
  }

  parseDateRange(str) {
    if (str === '') {
      return {}
    }

    try {
      const dates = str.split('-')
      if (dates.length === 0) {
        return {}
      } else if (dates.length === 1) {
        return {from: this.parseDateTime(dates[0].trim())}
      }

      const from = this.parseDateTime(dates[0].trim())
      const to = this.parseDateTime(dates[1].trim())

      if (from !== '' && to !== '') {
        return {from, to}
      } else if (from !== '') {
        return {from}
      } else {
        return {}
      }
    } catch (e) {
      return {}
    }
  }

  // @return [String] the full date format, including the time if configured
  fullFormat() {
    let format = this.dateFormatValue;
    if (this.timeValue) {
      format += ` ${this.timeFormatValue}`
    }
    return format
  }

  // Returns the month names in the configured locale.
  //
  // @param format [String] "long" (January) | "short" (Jan)
  // @return [Array] localised month names
  monthNames(format) {
    const formatter = new Intl.DateTimeFormat(this.localeValue, {month: format})
    return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(mm =>
      // Use the middle of the month to avoid timezone edge cases
      formatter.format(new Date(`2022-${mm}-15`))
    )
  }

  // Returns the day names in the configured locale, starting with the
  // firstDayOfTheWeekValue.
  //
  // @param format [String] "long" (Monday) | "short" (Mon) | "narrow" (M)
  // @return [Array] localised day names
  dayNames(format) {
    const formatter = new Intl.DateTimeFormat(this.localeValue, {weekday: format})
    const names = []
    // Ensure date in month is two digits. 2022-04-10 is a Sunday
    for (let i = this.firstDayOfWeekValue + 10, n = i + 7; i < n; i++) {
      names.push(formatter.format(new Date(`2022-04-${i}T00:00:00`)))
    }
    return names
  }

}

export {
  Datepicker
}
