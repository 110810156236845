import {Controller} from "@hotwired/stimulus"

/**
 * Click controller allows to convert a click on one element to a click on
 * another, e.g. clicking a table row is treated as clicking on a button in one
 * of its cells.
 *
 * Certain elements can be ignored by setting the `data-click-target` attribute.
 *
 *  %tr{controller: 'click', action: 'click#click->click'}
 *    %td
 *      %a{click_target: 'clickable', href: '/some/url'} Clicked by row click
 *    %td{data-click-target: 'ignored'}
 *      %a{href: '/some/other/url'} Ignored
 */
export default class extends Controller {
  static targets = ['clickable'];

  click(e) {
    // don't double the click on the clickable target
    if (e.target.dataset['clickTarget'] === 'clickable') {
      return;
    }

    let ignoreClick = false;
    this.element.querySelectorAll('[data-click-target="ignored"]').forEach((el) => {
      if (el.contains(e.target)) {
        ignoreClick = true;
      }
    })
    if (ignoreClick) {
      return;
    }
    this.clickableTarget.dispatchEvent(new Event('click'))
  }
}