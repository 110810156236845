export default class IsoDateTime {

  // new IsoDateTime()
  // new IsoDateTime('')
  //
  // new IsoDateTime(date)
  //
  // new IsoDateTime("2022-05-16")
  //
  // new IsoDateTime("2022", "05", "16")
  //
  // new IsoDateTime("2022", "05", "16", "12", "30")
  constructor(dateOrYear, month, day, hour = 0, min = 0) {
    if (dateOrYear && month && day) {
      this.yyyy = dateOrYear.toString()
      this.mm   = this.zeroPad(month)
      this.dd   = this.zeroPad(day)
      this.hh   = this.zeroPad(hour)
      this.min   = this.zeroPad(min)
    } else if (dateOrYear instanceof Date) {
      this.yyyy = dateOrYear.getFullYear().toString()
      this.mm   = this.zeroPad(dateOrYear.getMonth() + 1)
      this.dd   = this.zeroPad(dateOrYear.getDate())
      this.hh   = this.zeroPad(dateOrYear.getHours())
      this.min  = this.zeroPad(dateOrYear.getMinutes())
    } else if (dateOrYear) {
      const date = new Date(dateOrYear);
      this.yyyy = date.getFullYear().toString()
      this.mm   = this.zeroPad(date.getMonth() + 1)
      this.dd   = this.zeroPad(date.getDate())
      this.hh   = this.zeroPad(date.getHours())
      this.min  = this.zeroPad(date.getMinutes())
    } else {
      const today = new Date()
      this.yyyy = today.getFullYear().toString()
      this.mm   = this.zeroPad(today.getMonth() + 1)
      this.dd   = this.zeroPad(today.getDate())
      this.hh   = this.zeroPad(today.getHours())
      this.min  = this.zeroPad(today.getMinutes())
    }
  }

  toDateString() {
    return `${this.yyyy}-${this.mm}-${this.dd}`
  }

  toTimeString() {
    return `${this.hh}:${this.min}`
  }

  toString() {
    return `${this.toDateString()}T${this.toTimeString()}`;
  }

  setDayOfMonth(dayOfMonth) {
    const date = this.toDate()
    date.setDate(dayOfMonth)
    return new IsoDateTime(date)
  }

  setHour(hour) {
    const date = this.toDate()
    date.setHours(hour)
    return new IsoDateTime(date)
  }

  setMinute(minute) {
    const date = this.toDate()
    date.setMinutes(minute)
    return new IsoDateTime(date)
  }

  syncTime(otherDateTime) {
    return this.setHour(otherDateTime.hh).setMinute(otherDateTime.min)
  }

  // @param [Number] first day of the week (Sunday is 0)
  firstDayOfWeek(weekStart) {
    const date = this.toDate()
    date.setDate(date.getDate() - (7 + date.getDay() - weekStart) % 7)
    return new IsoDateTime(date)
  }

  // @param [Number] first day of the week (Sunday is 0)
  lastDayOfWeek(weekStart) {
    const date = this.toDate()
    date.setDate(date.getDate() + (weekStart + 6 - date.getDay()) % 7)
    return new IsoDateTime(date)
  }

  previousYear() {
    return this.increment('yyyy', -1)
  }

  nextYear() {
    return this.increment('yyyy', 1)
  }

  // @param [Boolean] whether to return the same day in the previous month (true)
  //    or the same day of the week in the previous month (false).
  previousMonth(sameDayOfMonth = true) {
    if (sameDayOfMonth) {
      return this.increment('mm', -1)
    } else {
      const month = this.mm
      let isoDate = this.increment('dd', -28)
      if (isoDate.mm == month) isoDate = isoDate.increment('dd', -7)
      return isoDate
    }
  }

  // @param [Boolean] whether to return the same day in the next month (true)
  //    or the same day of the week in the next month (false).
  nextMonth(sameDayOfMonth = true) {
    if (sameDayOfMonth) {
      return this.increment('mm', 1)
    } else {
      const month = this.mm
      let isoDate = this.increment('dd', 28)
      if (isoDate.mm == month) isoDate = isoDate.increment('dd', 7)
      return isoDate
    }
  }

  previousWeek() {
    return this.increment('dd', -7)
  }

  nextWeek() {
    return this.increment('dd', 7)
  }

  previousDay() {
    return this.increment('dd', -1)
  }

  nextDay() {
    return this.increment('dd', 1)
  }

  isToday() {
    return this.equalsDate(new IsoDateTime())
  }

  isFirstDayOfWeek(weekStart) {
    return this.toDate().getDay() == weekStart
  }

  equalsDate(isoDate) {
    return this.toDateString() == isoDate.toDateString()
  }

  before(isoDate) {
    return this.toString() < isoDate.toString()
  }

  after(isoDate) {
    return this.toString() > isoDate.toString()
  }

  // @param unit [String] 'dd' | 'mm' | 'yyyy'
  // @param count [Number]
  increment(unit, count) {
    let date
    if (unit == 'dd') {
      date = this.toDate()
      date.setDate(date.getDate() + count)
    } else {
      date = unit == 'yyyy'
           ? new Date(+this.yyyy + count, +this.mm - 1, 1, +this.hh, +this.min)
           : new Date(+this.yyyy, +this.mm - 1 + count, 1, +this.hh, +this.min)
      const endOfMonth = IsoDateTime.daysInMonth(date.getMonth() + 1, date.getYear())
      date.setDate(+this.dd > endOfMonth ? endOfMonth : +this.dd)
    }
    return new IsoDateTime(date)
  }

  // Returns the number of days in the month.
  //
  // @param month [Number] the month (1 is January)
  // @param year [Number] the year (e.g. 2022)
  // @return [Number] the number of days
  static daysInMonth(month, year) {
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) return 31
    if ([4, 6, 9, 11].includes(month)) return 30
    return this.isLeapYear(year) ? 29 : 28
  }

  static isLeapYear(year) {
    if ((year % 400) == 0) return true
    if ((year % 100) == 0) return false
    return year % 4 == 0
  }

  // Returns a two-digit zero-padded string.
  zeroPad(num) {
    return num.toString().padStart(2, '0')
  }

  toDate() {
    // Cannot use `new Date('YYYY-MM-DD')`: it is treated as UTC, not local.
    return new Date(+this.yyyy, +this.mm - 1, +this.dd, +this.hh, +this.min)
  }
}
