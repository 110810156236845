import {Controller} from "@hotwired/stimulus"
import {replaceByFetch} from "./mixins/replace_by_fetch";

/**
 * ColumnsController's purpose is to manage elements in months, month and day columns.
 *
 * It is based on the principle of reactivity and is controlled by data attributes.
 *  - each event is sent to the Ruby controller
 *  - Ruby controller processes event, renders appropriate template and send HTML result as JSON
 *  - template calls relevant cell(s) and renders result for Ruby controller
 *  - ColumnsController reads final data JSON and reactively overrides target columns,
 *    data coming from the server determines which elements will be overwritten
 *
 * Columns:
 *
 *  - months column
 *      - contains month elements for every month and year in current range
 *      - every element has 'data-date = YYYY-MM' (unique identifier)
 *      - if data for the given month are empty, the element has class 'no-data'
 *
 *  - month column
 *      - contains day elements for current month
 *      - days with empty data are grouped with 'range'
 *      - days are identified by 'data-date-day=YYYY-MM-DD' attribute
 *
 *  - day column
 *      - contains data for selected day
 *
 * Data structure:
 *
 *  - monthInMonthsColumn
 *      - contains data for one month in months column
 *
 *  - sourceMonthInMonthsColumn
 *      - if event changes current month (i.e. when you edit reading and move it to another month)
 *        this contains source month (month where event started)
 *
 *   - month
 *      - data for month column
 *
 *   - day
 *      - data for day column
 *
 *   - dayInMonthColumn
 *      - data for selected day in month column
 *
 * Behavior:
 *
 *  _submitHandler
 *      - sends event and receives target data
 *      - uses set of replacements methods to replace appropriate columns
 *
 *   destroy
 *      - deletes selected reading
 *      - uses set of replacements methods to replace appropriate columns
 *
 *   loadDay
 *      - loads selected day and replaces history
 *
 *   loadMonth
 *      - loads selected month
 *      - uses set of replacements methods to replace appropriate columns
 *
 * For more info and details see:
 *    app\frontend\javascript\controllers\mixins\columns_replacements.js
 *    app\frontend\javascript\controllers\mixins\columns_helpers.js
 *    app\views\readings\write_success.haml
 *    app\views\readings\destroy.html.haml
 *
 * Assumptions:
 *
 *  - there's only one link to each month (click->columns#loadMonth)
 *  - there's only one link to each day (click->columns#loadDay)
 *  - month/day links are descendants of respective selectors
 *  - links contain `date` query param using ISO8601 formatting
 *
 * Cooperation:
 *
 *  - DialogController - new & edit are done in dialogs with normal TurboFrame
 *                       functionality, the response might trigger `dialog:close`
 */

export default class extends Controller {
  static targets = ['month', 'day', 'months', 'monthInMonthsColumn', 'dayInMonthColumn'];
  static values = {
    // the initial date to be reflected in the query parameter
    date: String,
    ensureDate: {type: Boolean, default: true}
  }
  static classes = [
    // class toggled on selectors
    'current'
  ];
  static dateParamName = 'date';

  NO_DATA = 'no-data';

  connect() {
    super.connect();
    replaceByFetch(this);

    this.dayDateAttribute = this.initDayDateAttribute();

    this.submitHandler = this._submitHandler.bind(this);
    this.element.addEventListener('click', this.submitHandler);

  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('click', this.submitHandler);
  }

  destroy(e) {
    e.preventDefault();
    if (!confirm(e.currentTarget.dataset['turboConfirm'])) {
      return;
    }

    this.replaceByFetch(
      e.currentTarget.href,
      'DELETE',
      {
        frame: this.dayTarget,
        spinnerTarget: this.dayTarget,
        alertOnError: true,
        successHandler: (text) => {
          const htmlObject = document.createElement('div');
          htmlObject.innerHTML = JSON.parse(text).html;

          this.replaceMonthSelectorItems(htmlObject)
          this.trimMonthsColumn();
          this.replaceMonthColumn(htmlObject);
          this.replaceDaySelector(htmlObject);
          this.replaceDayColumn(htmlObject);
        },
      },
    );
  }

  _submitHandler(e) {
    if (!e.target.form
      || e.target.type !== 'submit'
      || 'ignoreInColumnsController' in e.target.form?.dataset)
      return;

    e.preventDefault();

    const monthColumnIsEmpty = this.monthTarget
      .querySelector('[data-date-day]') === null;

    const data = new FormData(e.target.form);
    const source = e.target.form.id === 'new_reading' || e.target.form.id === 'edit-reading'
      ? 'reading'
      : 'data_point';
    data.append(`${source}[month_column_empty?]`, monthColumnIsEmpty)
    const href = e.target.form.action;
    const method = e.target.form.method.toUpperCase();
    const dialogTarget = document.querySelector('dialog[open]');

    this.replaceByFetch(
      href,
      method,
      {
        spinnerTarget: dialogTarget,
        data,
        useErrorHandling: true,
        successHandler: (text, _frame, _replaceMode, hasError) => {
          const htmlObject = document.createElement('div');

          if (this.hasJsonStructure(text)) {
            htmlObject.innerHTML = JSON.parse(text).html;
          } else {
            htmlObject.innerHTML = text;
          }

          if (hasError) {
            /* If the form processing ends with an error, replaceByFetch returns
               the modified form with the errors marked.
               This form has the same id as the open overlay form.  */
            const replaceWith = htmlObject.firstChild;
            /* Now we need to retrieve the original form by its id and overwrite
               it with the form returned by replaceByFetch.
            */
            const toBeReplaced = document.getElementById(replaceWith.id);
            if (toBeReplaced) {
              toBeReplaced.replaceWith(replaceWith);
            }

          } else {
            const dialogTarget = e.target.closest('[data-dialog-target="dialog"]');

            if (dialogTarget) {
              dialogTarget.dispatchEvent(new CustomEvent('dialog:close'));
            }

            this.fillGap(htmlObject);
            this.replaceMonthSelectorItems(htmlObject)
            this.replaceMonthColumn(htmlObject);
            this.replaceDaySelector(htmlObject);
            this.trimMonthsColumn();
            this.replaceDayColumn(htmlObject);
          }
        }
      }
    );
  }

  loadDay(e) {
    e.preventDefault();
    const dayTargetAttribute = this.extractDataDayTarget(e.currentTarget);

    /* stop execution if clicked day is the same as previously selected day */
    if (dayTargetAttribute === this.dayDateAttribute) return;

    this.dayDateAttribute = dayTargetAttribute;
    this._loadDay(dayTargetAttribute, e.currentTarget.href);
  }

  _loadDay(dateDayAttribute, linkHref) {
    this.replaceByFetch(
      linkHref,
      'GET',
      {
        frame: this.dayTarget,
        spinnerTarget: this.dayTarget,
        onSuccess: () => {
          this.selectDayAndWriteHistory(dateDayAttribute, linkHref);
        },
        replaceMode: 'innerHTML'
      }
    );
  }

  loadMonth(e) {
    e.preventDefault();

    const monthTargetAttribute = this.extractDataMonthTarget(e.currentTarget);

    /* stop execution if clicked month is the same as previously selected month */
    if (monthTargetAttribute === this.montDateAttribute) return;

    this._loadMonth(monthTargetAttribute, e.currentTarget.href);
  }

  _loadMonth(monthAttribute, linkHref) {
    this.showSpinner(this.dayTarget); // the whole dayTarget gets replaced, no need to hide it

    this.replaceByFetch(
      linkHref,
      'GET',
      {
        frame: this.monthTarget,
        spinnerTarget: this.monthTarget,
        successHandler: (text) => {
          const htmlObject = document.createElement('div');
          htmlObject.innerHTML = JSON.parse(text).html;
          this.replaceMonthColumn(htmlObject);
          this.replaceDayColumn(htmlObject);
          this.selectMonth(monthAttribute);
        }
      }
    );
  }

  /* we always overwrite the contents of the day column */
  replaceDayColumn(htmlObject) {
    const dayToReplace = htmlObject.querySelector('[data-columns-target="day"]');
    if (!dayToReplace) return;
    this.dayTarget.outerHTML = dayToReplace.outerHTML;

    const dateDayAttribute = dayToReplace.dataset['dateDay'];

    const dayToLoad = this.monthTarget.querySelector(`[data-date-day="${dateDayAttribute}"]`);
    const linkHref = dayToLoad ? this.newUrl(dayToLoad.querySelector('a').href) : null;

    this.dayDateAttribute = dateDayAttribute;

    /* wait one script cycle to make sure this corresponding element exists */
    setTimeout(() => {
      linkHref && this.selectDayAndWriteHistory(dateDayAttribute, linkHref);
    }, 1)
  }

  /* if event date didn't change, we replace only one item in month column */
  replaceDaySelector(htmlObject) {
    const dayReplaceWith = htmlObject.querySelector('[data-columns-target="dayInMonthColumn"]');
    if (dayReplaceWith) {
      const targetDayDataAttribute = dayReplaceWith.dataset.dateDay;
      const dayToBeReplaced = this.monthTarget.querySelector(`[data-date-day="${targetDayDataAttribute}"]`);

      this.dayDateAttribute = targetDayDataAttribute;

      if (dayToBeReplaced) {
        dayToBeReplaced.replaceWith(dayReplaceWith);
      }
    }
  }

  /* if event date changed, we replace whole month column */
  replaceMonthColumn(htmlObject) {
    const columnReplaceWith = htmlObject.querySelector('[data-columns-target="month"]');

    if (columnReplaceWith) {
      this.monthTarget.outerHTML = columnReplaceWith.outerHTML;
    }
  }

  /* if event does not change the month, only one element is overwritten */

  /* if the event moves the reading to another month, the target month and the source month
     (the one in which the event started) are overwritten */
  replaceMonthSelectorItems(htmlObject) {
    const monthReplaceWith = htmlObject.querySelector('[data-columns-target="monthInMonthsColumn"]');
    if (!monthReplaceWith) return;
    const targetMonthDataAttribute = monthReplaceWith.dataset.date;
    const monthToBeReplaced = this.monthsTarget.querySelector(`[data-date="${targetMonthDataAttribute}"]`);

    if (monthToBeReplaced) {
      monthToBeReplaced.replaceWith(monthReplaceWith);
    } else {
      const monthsColumn = this.monthsTarget;

      monthsColumn.append(monthReplaceWith);
    }

    const previousMonthReplaceWith = htmlObject.querySelector('[data-columns-target="sourceMonthInMonthsColumn"]');
    if (previousMonthReplaceWith) {
      const targetMonthDataAttribute = previousMonthReplaceWith.dataset.date;
      const monthToBeReplaced = this.monthsTarget.querySelector(`[data-date="${targetMonthDataAttribute}"]`);

      monthToBeReplaced.replaceWith(previousMonthReplaceWith);
    }

    this.selectMonth(targetMonthDataAttribute)
  }

  /* fills gap between first and next month with no-data months */
  fillGap(htmlObject) {
    const columnGap = htmlObject.querySelector('#gaps');
    const monthsColumn = this.monthsTarget;

    if (columnGap) {
      monthsColumn.append(...columnGap.querySelectorAll('li'));
    }

    /* Duplicate blank months may occur, we need to remove them */
    setTimeout(() => {
      const currentEmptyMonths = monthsColumn.querySelectorAll('.no-data');

      currentEmptyMonths.forEach(month => {
        const date = month.dataset.date;
        const isDuplicate = monthsColumn.querySelectorAll(`[data-date="${date}"]`).length > 1;
        if (isDuplicate) {
          month.remove();
        }
      })
    }, 1)
  }

  /* removes any months with no data at the bottom of the column,
     once it encounters a month with data, it stops */
  trimMonthsColumn() {
    const months = Array.from(this.monthsTarget
      .querySelectorAll('li')
    ).reverse();

    months.some((month, index) => {
      const containsNoData = month.classList.contains(this.NO_DATA);
      if (containsNoData && months.length > index + 1) {
        month.remove();
      }
      return !containsNoData;
    })
  }

  /* initializes dayDateAttribute (pointer to the current day) */
  initDayDateAttribute() {
    const params = new URLSearchParams(window.location.search);
    return params.get('date') || '';
  }

  /* extracts the data attribute used to control the current day */
  extractDataDayTarget(element) {
    const tagName = element.tagName.toLowerCase();
    return tagName === 'a'
      ? element.closest('li').dataset.dateDay
      : element.dataset.dateDayTarget;
  }


  /* extracts the data attribute used to control the current month */
  extractDataMonthTarget(element) {
    return element.closest('li').dataset.date;
  }

  /* walks through months column, remove or add current class */
  selectMonth(monthAttribute) {
    const liElements = this.monthsTarget.querySelectorAll('li');

    liElements.forEach(el => {
      if (el.dataset.date === monthAttribute) {
        el.classList.add(this.currentClass);
      } else {
        el.classList.remove(this.currentClass);
      }
    })
  }

  /* walks through month column, remove or add current class and writes to history */
  selectDayAndWriteHistory(dateDayAttribute, linkHref) {
    history.pushState(null, '', this.newUrl(linkHref));

    const liElements = this.monthTarget.querySelectorAll('li')

    liElements.forEach(el => {
      if (el.dataset.dateDay === dateDayAttribute) {
        el.classList.add(this.currentClass);
      } else {
        el.classList.remove(this.currentClass);
      }
    })
  }

  /* creates new url from link href */
  newUrl(href) {
    let location = window.location;
    const params = new URLSearchParams(location.search);
    params.set(this.constructor.dateParamName, this.extractDateParam(href));
    return `${location.pathname}?${params.toString()}${location.hash}`;
  }

  /* extract date param from url */
  extractDateParam(href) {
    const location = new URL(href, window.location.href);
    const params = new URLSearchParams(location.search);
    return params.get(this.constructor.dateParamName);
  }

  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]' || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }
}