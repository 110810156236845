import {Controller} from "@hotwired/stimulus"
import {replaceByFetch} from "./mixins/replace_by_fetch";

export default class extends Controller {
  connect() {
    super.connect();
    replaceByFetch(this);

    this.refreshContentHandler = this._refreshContentHandler.bind(this);
    this.element.addEventListener('profiles:refreshContent', this.refreshContentHandler);
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('profiles:refreshContent', this.refreshContentHandler);
    window.removeEventListener('popstate', this.navigationHandler);
  }

  loadPage(event) {
    event.preventDefault();
    let targetUrl = event.currentTarget.getAttribute('href') || event.currentTarget.getAttribute('action');

    const find = event.currentTarget.querySelector('[name="find"]');
    const jump = event.currentTarget.querySelector('[name="jump"]');
    const queryString = targetUrl.split('?')[1];

    const params = queryString ? new URLSearchParams(queryString) : new URLSearchParams();

    if (find && params.has('newer_than')) {
      params.set('newer_than', find.value);
      jump && params.set('jump', jump.value);

      targetUrl = `${targetUrl.split('?')[0]}?${params.toString()}`;
    }

    this._loadPage(targetUrl);
  }

  _loadPage(url) {
    this.replaceByFetch(
      url,
      'GET',
      {
        frame: this.tableTarget,
        spinnerTarget: this.tableTarget,
        onSuccess: () => {
          history.pushState(null, document.title, url);
        },
        replaceMode: 'outerHTML'
      }
    );
  }

  destroy(event) {
    event.preventDefault();
    if (!confirm(event.currentTarget.dataset['turboConfirm'])) {
      return;
    }

    const destroyUrl = event.currentTarget.href;
    const targetUrl = event.currentTarget.baseURI;

    this.replaceByFetch(
      destroyUrl,
      'DELETE',
      {
        onSuccess: () => {
          this._loadPage(new URL(targetUrl));
        },
        alertOnError: true
      }
    );
  }

  _newUrl(event) {
    const params = new URLSearchParams();
    params.set('newer_than', event.detail.newer_than);
    params.set('current', event.detail.current);

    return `${window.location.pathname}?${params.toString()}`
  }

  _refreshContentHandler(event) {
    this._loadPage(this._newUrl(event))
  }
}
