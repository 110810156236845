import {Controller} from "@hotwired/stimulus"

/**
 * ReportConfiguration controller enables to add new entities to the report
 * configuration. It relies on the search controller to display the search
 * results and then takes over to add the picked entity to the matrix.
 *
 * It keeps track of already present entity IDs to exclude them from search.
 *
 * It updates a hidden field listing required entity features that configures
 * the search behaviour.
 *
 *  %div{ data: { controller: 'report-configuration' }}
 *    %label
 *      Template
 *      %select{data: { action: 'change->search#updateRequiredFeatures' }}
 *        %option{data: { }'theoretical_consumption'} Branch template
 *    %table{data: { report_configuration_target: 'matrix', matrix_id: template.id }}
 *       %tr
 *        %td Entity id = 42
 *       %tr
 *        %td Entity id = 616
 *
 *    %form.search-form.ommitting-details
 *       %input{type: :hidden,
 *               name: :exclude_ids,
 *               value: '42,616',
 *               data: { report_configuration_target: 'entityIds' }}
 *       %input{type: :hidden,
 *               name: :required_features,
 *               value: 'theoretical_consumption,floor_plans',
 *               data: { report_configuration_target: 'requiredFeatures' }}
 *       %input{type: :hidden,
 *               name: :template_id,
 *               value: 9,}
 *        %ul
 *          %li{data: { report_configuration_target: 'searchResult' }}
 *            %button{data: { action: 'click->report-configuration#add',
 *                            report_configuration_entity_id_param: 13,
 *                            report_configuration_template_id_param: 'entity-13',
 *                            report_configuration_matrix_template_id_param: 9
 *            %template#entity-13
 *              %tr
 *                %td Entity id = 13
 */
export default class extends Controller {
  static targets = ['entityIds', 'requiredFeatures', 'matrix', 'searchResult']

  add(e) {
    const matrixId = e.params.matrixTemplateId;
    const matrix = this.matrixTargets.find(
        (matrix) => matrix.dataset.matrixId === matrixId.toString()
    );
    this.entityIdsTarget.value = this.entityIdsTarget.value.split(',').concat(e.params.entityId).join(',');
    matrix.insertAdjacentHTML('beforeend', document.getElementById(e.params.templateId).innerHTML.trim());
    e.currentTarget.closest('[data-report-configuration-target="searchResult"]').remove();
  }

  updateRequiredTemplates(e) {
    // Stimulus doesn't handle params from an option element automatically
    this.requiredFeaturesTarget.value =
      e.target.selectedOptions[0].dataset.reportConfigurationRequiredFeaturesParam;
  }
}