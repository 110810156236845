import {Controller} from "@hotwired/stimulus"

/**
 * TemplateController#replace allows to replace scopeTarget with template
 * content, e.g. replace a button with pre-rendered form
 *
 *  %div{data: { controller: 'template'} }
 *    %template{data: { template_target: 'template' }}
 *      Template to replace button
 *
 *    %button{type: :button,
 *            data: { action: 'click->template#replace',
 *            template_target: 'scope' }}
 *      Replace this button with the template
 *
 * TemplateController#insert allows to insert template content before the end
 * of scopeTarget, e.g. a new row to a table.
 *
 *  %div{data: { controller: 'template'} }
 *    %table
 *      %tbody{data: { template_target: 'scope' }}
 *        %tr
 *          %td Existing row
 *
 *    %template{data: { template_target: 'template' }}
 *      %tr
 *        %td New row

 *    %button{type: :button,
 *            data: { action: 'click->template#insert' }}
 *       Add a new row
 *
 * TemplateController#ensure inserts a template content into each scope target
 * at most once. It uses the sample template for all scopes, the template can
 * have a placeholder which gets replaced by an event parameter. The triggering 
 * element must be within the scope.
 *
 * The motivating case is navigational tree context menu which is rarely used
 * (so prospectively rendering it for every node is wasteful) and differs only
 * by an ID in the URLs of the menu items.
 *
 * %div{data: { controller: 'template',
 *              template_placeholder_value: ':id'}}
 *  %template{data: { template_target: 'template' }}
 *    Fancy content with some/:id/url
 *
 *  %div{data: { template_target: 'scope' }}
 *    %button{type: :button,
 *            data: { action: 'click->delayed-content#ensure',
 *                    template_replacement_param: 42 }}
 *      Ensure content exists with ID=42
 *
 *  %div{data: { template_target: 'scope' }}
 *    %button{type: :button,
 *            data: { action: 'click->delayed-content#ensure',
 *                    template_replacement_param: 616 }}
 *      Ensure content exists with ID=616
 *
 */
export default class extends Controller {
  static values = {
    placeholder: String
  }
  static targets = [
    'template',
    'scope'
  ]

  insert() {
    const content = this.templateTarget.innerHTML;
    this.scopeTarget.insertAdjacentHTML('beforeend', content);
  }

  replace() {
    if (!this.hasScopeTarget) {
      return;
    }
    this.scopeTarget.replaceWith(this.templateTarget.content.cloneNode(true))
  }

  ensure(e) {
    const scope = e.target.closest('[data-template-target="scope"]');
    if (!scope) {
      return console.error('event target is not in a scope target');
    }

    if (scope.querySelector('[data-delayed_content="delayed"]')) {
      return;
    }

    let rawHtml = this.templateTarget.innerHTML;
    if (e.params.replacement) {
      rawHtml = rawHtml.replaceAll(this.placeholderValue, e.params.replacement);
    }
    scope.insertAdjacentHTML('beforeend', rawHtml);
    scope.children[scope.children.length - 1].dataset['delayed_content'] = 'delayed';
  }

}