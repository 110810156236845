import {Controller} from "@hotwired/stimulus";
import {useClickOutside} from "stimulus-use";

/**
 * Details controller allows to
 *
 * (a) close a details element by clicking outside of it. Opening is left to
 *     native browser behavior.
 * (b) when hovered over, it will immediately load all lazy-loaded turbo-frames
 */
export default class extends Controller {
  connect() {
    super.connect();
    useClickOutside(this);

    this.clickOutsideListener = this._clickOutsideListener.bind(this);
    document.addEventListener('click', this.clickOutsideListener);

    this.hoverListener = this._hoverListener.bind(this);
    this.element.addEventListener('mouseover', this.hoverListener);

    this.turboNavigationListener = this._close.bind(this);
    this.element.addEventListener('turbo:click', this.turboNavigationListener)
  }

  disconnect() {
    super.disconnect();
    document.removeEventListener('click', this.clickOutsideListener);
    this.element.removeEventListener('turbo:click', this.turboNavigationListener);
  }

  _clickOutsideListener(event) {
    if (!this.element.open) return;

    if (event.target.isConnected && !this.element.contains(event.target)) {
      this._close();
    }
  }

  _hoverListener(event) {
    this._loadLazyTurboFrames();
    this.element.removeEventListener('mouseover', this.hoverListener);
  }

  _loadLazyTurboFrames() {
    this.element.querySelectorAll('turbo-frame[loading="lazy"]').forEach((frame) => {
      frame.removeAttribute('loading')
    })
  }

  _close() {
    this.element.open = false;
  }
}