import {Controller} from "@hotwired/stimulus"

/**
 * BrowserViewportsController sends browser viewport data to the server.
 * The controller is used to track the browser viewport width, viewport height
 * and whether the device used by the user is touch device.
 *
 * Simply add this code to the page where you want
 * to detect the browser viewport:
 *    .hidden{data: {controller: 'browser-viewports', browser_viewports_target: 'withoutChart'}}
 *
 * If the page automatically loads the chart, and you want to wait until chart is loaded,
 *  add the "loaded-chart" class to the css chart wrapper and use this code:
 *    .hidden{data: {controller: 'browser-viewports', browser_viewports_target: 'withChart'}}
 */

export default class extends Controller {
  static targets = ['withChart', 'withoutChart']
  dataSent = true;
  connect() {
    super.connect();
    this.dataSent = false;
    this.processViewport();
  }

  processViewport() {
    if (!this.hasWithoutChartTarget && !this.hasWithChartTarget) {
      return;
    }
    if (this.hasWithoutChartTarget) {
      addEventListener("DOMContentLoaded", () => {
        if (!this.dataSent) {
          this.sendBrowserData().then(() => {
            this.dataSent = true;
          })
        }
      });
    }
    if (this.hasWithChartTarget) {
      const observer = new MutationObserver((mutations, observer) => {
        const element = document.querySelector('.loaded-chart');
        if (element && !this.dataSent) {
          this.dataSent = true;
          this.sendBrowserData().then(() => {
            observer.disconnect();
          });
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    }
  }

  async sendBrowserData() {
    const url = '/browser_resolutions';

    const touch = 'ontouchstart' in window || navigator.maxTouchPoints;
    const data = {
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      touch_device: touch
    }
    await fetch(url, {
      method: "post",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify(data)
    })
  }
}