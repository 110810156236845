import syncMenus from "./sync-menus";
import backTo from "./back-to";

const turboHelpers = () => {
  syncMenus();
  backTo();
}

export default turboHelpers;

