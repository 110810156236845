import {Controller} from "@hotwired/stimulus"
import {replaceByFetch} from "./mixins/replace_by_fetch";

/**
 * DelayedContent controller allows to use a placeholder in HTML and have it
 * fully replaced by a XHR call's response only once the placeholder appears on
 * the screen.
 *
 *    %div{data: { controller: 'delayed-content',
 *        delayed_content_src_value: some_path}}
 */
export default class extends Controller {
  static values = {
    src: String
  }

  connect() {
    super.connect();

    replaceByFetch(this);

    this.observer = new IntersectionObserver(function(entries) {
      // If intersectionRatio is 0, the target is out of view
      if(entries[0].intersectionRatio <= 0) return;

      this.replaceByFetch(this.srcValue, 'GET', { replaceMode: 'outerHTML' });
      this.observer.disconnect();
    }.bind(this));
    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
    super.disconnect();
  }
}