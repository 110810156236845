import {Controller} from "@hotwired/stimulus"

/**
 * VirtualSources controller manages the process of adding new meters
 * (both virtual and physical) to a matrix of consumption sources. It interacts
 * with a search interface and updates the table of selected meters dynamically,
 * ensuring that previously added meters are excluded from search results.
 *
 * This controller works alongside a search controller
 * to display the search results and allows users to add meters (virtual or physical)
 * to the table matrix.
 * That search controller works with the search form to dynamically including the list of excluded meter IDs
 * These IDs are passed via hidden input fields to ensure
 * that previously added meters are excluded from the search results.
 *
 *
 * Based on the `virtualScope` parameter,
 * it determines whether the selected meter belongs to the virtual or physical category.
 *
 * The controller keeps track of the IDs of already-added
 * meters, both virtual and physical, by updating hidden input fields that store comma-separated
 * lists of these IDs. These lists are used to exclude already-added meters from subsequent search results.
 *
 *
 * - matrixOutsideSubtreeVirtualTarget / matrixOutsideSubtreePhysicalTarget: These are the table containers where selected meters
 *   from the oustide of the current entity subtree scope (virtual or physical) are added.
 *
 * - matrixSubtreeVirtualAndPhysicalTarget:
 *   A target that includes a subtree of existing tables virtual and physical. This subtree contains rows of subtree meters,
 *   which must also be excluded from search results, because we search from the outside of the current entity subtree.
 *   Meters in the subtree are identified by their `meter_id` attributes.
 *
 * - meterVirtualIdsTarget / meterPhysicalIdsTarget: Hidden input fields that store the excluded IDs
 *   (those already added to the matrix) for virtual and physical meters.
 *
 * - searchResultVirtualTarget / searchResultPhysicalTarget: Containers for search result rows that
 *   allow users to pick meters and add them to the matrix.
 *
 *  %div{ data: { controller: 'virtual-sources' }}
 *    -- table with subtree sources
 *    %table{data: { virtual_sources_target: "matrixSubtreeVirtualAndPhysical" } }
 *      %tr{data: {meter_id: 1 } }
 *       %td Meter id = 1
 *    -- table with search outside of the subtree
 *    %div
 *     %table{data: { virtual_sources_target: "matrixOutsideSubtreeVirtual/Physical" }}
 *        %tr{data: {meter_id: 42}}
 *         %td Meter id = 42
 *        %tr{data: {meter_id: 46}}
 *         %td Meter id = 616
 *
 *     %form.search-form.ommitting-details
 *        %input{type: :hidden,
 *               name: :exclude_virtual/physical_ids,
 *               data: { virtual_sources_target: 'meterVirtual/PhysicalIds' }}
 *         %ul
 *           %li{data: { virtual_sources_target: 'searchResultVirtual/Physical' }}
 *             %button{data: { action: 'click->virtual-sources#add',
 *                             virtual_sources_template_id_param: template_id,
 *                             virtual_sources_meter_id_param: meter.id,
 *                             virtual_sources_virtual_scope_param: meter.virtual.to_s }}
 *             %template#meter-meter.id
 *               %tr
 *                 %td Meter id = meter.id
 */
export default class extends Controller {
    static targets = ['meterVirtualIds', 'meterPhysicalIds', 'matrixOutsideSubtreeVirtual', 'matrixOutsideSubtreePhysical', 'searchResultVirtual', 'searchResultPhysical', 'matrixSubtreeVirtualAndPhysical']

    connect() {
        this.updateExcludedIds();
    }

    add(e) {
        if (e.params.virtualScope) {
            this.addVirtualSource(e);
        } else {
            this.addPhysicalSource(e);
        }
        this.updateExcludedIds();
    }

    addVirtualSource(e) {
        const templateId = e.params.templateId;

        this.matrixOutsideSubtreeVirtualTarget.insertAdjacentHTML('beforeend', document.getElementById(templateId).innerHTML.trim());
        e.currentTarget.closest('[data-virtual-sources-target="searchResultVirtual"]').remove();
    }

    addPhysicalSource(e) {
        const templateId = e.params.templateId;

        this.matrixOutsideSubtreePhysicalTarget.insertAdjacentHTML('beforeend', document.getElementById(templateId).innerHTML.trim());
        e.currentTarget.closest('[data-virtual-sources-target="searchResultPhysical"]').remove();
    }

    getExcludedVirtualIds() {
        return [
            this.meterVirtualIdsTarget.value,
            ...Array.from(this.matrixOutsideSubtreeVirtualTarget.children).map(row => row.dataset.meterId),
            ...Array.from(this.matrixSubtreeVirtualAndPhysicalTarget.children)
                .filter(row => row.dataset.meterId !== undefined)
                .map(row => row.dataset.meterId)
        ];
    }

    getExcludedPhysicalIds() {
        return [
            this.meterPhysicalIdsTarget.value,
            ...Array.from(this.matrixOutsideSubtreePhysicalTarget.children).map(row => row.dataset.meterId),
            ...Array.from(this.matrixSubtreeVirtualAndPhysicalTarget.children)
                .filter(row => row.dataset.meterId !== undefined)
                .map(row => row.dataset.meterId)
        ];
    }

    updateExcludedIds() {
        const virtualIds = this.getExcludedVirtualIds().join(',');
        const physicalIds = this.getExcludedPhysicalIds().join(',');

        this.meterVirtualIdsTarget.value = virtualIds;
        this.meterPhysicalIdsTarget.value = physicalIds;
    }
}