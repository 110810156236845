import {Controller} from "@hotwired/stimulus"
import {formSerialization} from "./mixins/form_serialization";
import {fetches} from "./mixins/fetches";

export default class extends Controller {
  static targets = [
    'targets',
    'expenseTemplate',
    'coreFields',
    'expense',
    'range',
    'entityId'
  ]
  static values = {
    targetsUrl: String,
    previewUrl: String
  }

  connect() {
    super.connect();
    fetches(this);
    formSerialization(this);

    this.element.querySelectorAll('[data-action]').forEach(el => {
      if (el.dataset['action'].includes(`->reconciliation#loadTargets`)) {
        this.loadTargets({target: el});
      }
    })

    this.coreFieldsTarget.addEventListener('change', () => {
      this.expenseTargets.forEach(e => this.previewExpense(e))
    });
  }

  loadTargets(e) {
    this.targetsTarget.dataset.controller = 'spinner';

    const url = new URL(this.targetsUrlValue);
    url.searchParams.append('entity_id', this.entityIdTarget.value)
    url.searchParams.append('range', this.rangeTarget.value)
    this.fetchPartial(url).then(res => {
      res.text().then(text => {
        this.targetsTarget.innerHTML = text
      })
    }).finally(_ => {
      this.targetsTarget.dataset.controller = '';
    })
  }

  addExpense(e) {
    const content = this.expenseTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    e.target.insertAdjacentHTML('beforebegin', content);
  }

  expenseTargetConnected(expense) {
    expense.addEventListener('change', (e) => {
      if (e.target.dataset.reconciliationOnChange === 'ignore') {
        return;
      }

      this.previewExpense(expense);
    });
  }

  previewExpense(expense) {
    const preview = expense
      .querySelectorAll('[data-reconciliation-target="expense_preview"]')
      .values()
      .find(preview => !this.isDisabled(preview))
    preview.dataset.controller = 'spinner';

    const data = new FormData();
    this.collectInputValues(this.coreFieldsTarget, data);
    this.collectInputValues(expense, data);

    this.fetchPartial(
      this.previewUrlValue,
      {
        method: 'POST',
        body: data,
      }
    ).then(res => {
      res.text().then(text => {
        preview.innerHTML = text;
      })
    }).finally(_ => {
      preview.dataset.controller = '';
    })
  }

  isDisabled(node) {
    if (node.disabled) return true

    const parent = node.parentNode;
    if (parent) return this.isDisabled(parent);

    return false
  }
}