import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['effectiveDate', 'specificDate', 'auto', 'manual'];
  static classes = ['hidden'];

  connect() {
    super.connect();
    this.updatePreviewHandler = this._updatePreview.bind(this);
    this.element.addEventListener('property:updatePreview', this.updatePreviewHandler)
  }
  disconnect() {
    super.disconnect();
    this.element.removeEventListener('property:updatePreview', this.updatePreviewHandler)
  }

  toggleEffectiveDate(e) {
    let valueChanged = false;
    switch (e.currentTarget.dataset.dataType) {
      case 'boolean':
        valueChanged =
          (e.currentTarget.checked && e.currentTarget.dataset.persistedValue === '0') ||
          (!e.currentTarget.checked && e.currentTarget.dataset.persistedValue === '1');
        break;
      default:
        valueChanged = e.currentTarget.value !== e.currentTarget.dataset.persistedValue;
    }

    this._toggle(this.effectiveDateTarget, valueChanged);
  }

  selectInfinity() {
    this._toggle(this.specificDateTarget, false);
  }

  selectSpecificDate() {
    this._toggle(this.specificDateTarget, true);
  }

  selectManual() {
    this._toggle(this.manualTarget, true);
    this._toggle(this.autoTarget, false);
  }

  selectAuto() {
    this._toggle(this.manualTarget, false);
    this._toggle(this.autoTarget, true);
  }

  _toggle(el, display) {
    el.classList.toggle(this.hiddenClass, !display)
    el.hidden = !display;
    el.disabled = !display;
  }

  _updatePreview(e) {
    const template = document.getElementById(e.detail.templateId);
    document.getElementById(e.detail.id).outerHTML = template.innerHTML;
    template.remove();
  }
}