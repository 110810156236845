/**
 * Back menu link always goes back to the main page.
 * Back content link can lead to another page or the main page.
 */

/* save last main page path */
let lastMainPagePath = null;

/* get main page path from the window object */
const mainPagePath = window.main_page_path;

/* remove listeners on page unload */
addEventListener("beforeunload", () => {
  removeListeners();
});

const backTo = () => {

  /* if the main page path is not set, return */
  if (!mainPagePath) return;

  document.addEventListener('turbo:load', (e) => {
    addListeners();

    /* save the last main page path */
    if (isMainPage()) {
      lastMainPagePath = parsePath(e.detail.url);
    }
  })

}

/* get back content link */
const backContentLink = () => {
  return document.querySelector('[data-back-to-previous-page]');
}

/* get back menu link */
const backMenuLink = () => {
  return document.querySelector('[data-back-to-main-page]');
}

/* check if the current page is the main page */
const isMainPage = () => {
  return Boolean(document.querySelector('[data-is-main-page]'))
}

/* go back to the previous page with menu link click */
const backToFromMenuLink = (e) => {
  e.preventDefault();
  /* if the last main page path is saved, visit it,
     otherwise visit the main page */
  if (lastMainPagePath) {
    Turbo.visit(lastMainPagePath);
  } else {
    Turbo.visit(mainPagePath);
  }
}

/* go back to the previous page with back content click */
const backToFromContentLink = (e) => {
  e.preventDefault();
  let targetLink = '';
  const originalHref = e.currentTarget.href;
  const originalPath = parsePath(originalHref);
  /* if back content target is the main page, visit the last main page path,
     else visit back content link */
  if (originalPath === mainPagePath) {
    targetLink = lastMainPagePath ? lastMainPagePath : mainPagePath;
  } else {
    targetLink = originalPath;
  }
  Turbo.visit(targetLink);
}

const parsePath = (url) => {
  const urlParsed = new URL(url);
  return urlParsed.pathname + urlParsed.search;
}

const addListeners = () => {
  backContentLink() && backContentLink().addEventListener('click', backToFromContentLink);
  backMenuLink() && backMenuLink().addEventListener('click', backToFromMenuLink);
}

const removeListeners = () => {
  backContentLink() && backContentLink().removeEventListener('click', backToFromContentLink);
  backMenuLink() && backMenuLink().removeEventListener('click', backToFromMenuLink);
}


export default backTo;