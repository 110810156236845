import {Controller} from "@hotwired/stimulus"

/**
 * ColumnsSettings controller dynamically manages the visibility of table columns
 * based on user preferences. It integrates with the backend using `Model::Collection`
 * and resp. `ModelList` to load and manage columns with efficient database operations.
 * This improves performance by loading only necessary columns, minimizing redundant queries,
 * and optimizing page load times.
 * It allows users to hide or show certain columns, and persists these preferences
 * across sessions using cookies.
 *
 * This controller is flexible enough to be applied across different tables, as
 * the column settings can be associated with a customizable cookie name.
 * If you use this controller, your operation must contain the `step Macro::TableColumnsFromSession` macro step
 * to ensure columns for action views.
 *
 * - The `toggle` target represents each checkbox in the UI that controls the visibility
 *   of a table column. These checkboxes are automatically linked to the corresponding
 *   column via `data-column-name` attributes.
 *
 * - Cookie storage is leveraged to save and retrieve user preferences. The name of the cookie
 *   storing these preferences can be dynamically specified using the `cookieValue` passed
 *   through the HTML `data-columns-settings-cookie-value` attribute.
 *
 * - Whenever a user toggles a column on or off, the `savePreferences` method is called,
 *   which stores the updated column settings in the cookie and applies these settings
 *   to the table in real time.
 *
 * ### Key Attributes
 *
 * - `data-columns-settings-cookie-value`: Allows dynamic naming of the cookie for each
 *    table, ensuring that different tables can persist their settings independently.
 *
 * ### Targets:
 *
 * - `toggle`: Represents checkboxes that control the visibility of specific columns.
 *    Each checkbox corresponds to a table column, identified by a `data-column-name`
 *    attribute in the table's HTML.
 * - `saveButton`: Represents button that applies the visibility of the save button.
 *    Save button is disabled by default to omit unnecessary re-loading and enable
 *    to apply chosen selection when the settings have change any of the toggle state.
 *
 * ### Applying Rules to Multiple Columns
 *
 * To apply the same visibility rule to multiple columns, ensure that both the header
 * and the corresponding cell elements share the same `column_name`. This allows
 * the toggles to control the visibility of all these columns simultaneously.
 *
 * %div{ data: { controller: 'columns-settings', columns_settings_cookie_value: 'cookie_name' }}
 * %table
 *   %thead
 *     %tr
 *       %th{data: { column_name: 'columnName' }} Column Name
 *       ...
 *
 *   %tbody
 *     %tr
 *       %td{data: { column_name: 'columnName' }} Dummy
 *       ...
 *
 * %div.menu{data: { controller: 'menu' }}
 *   %button{ data: { action: 'click->menu#toggle' }} Column Options
 *   %ul
 *     %li
 *      %div{data: { action: 'click->columns-settings#toggleRow' }}
 *        %input{ type: 'checkbox', name: 'columnName', checked: true, data: { columns_settings_target: 'toggle', action: 'columns-settings#savePreferences' } }
 *        %label Toggle Column Name
 *     %button{type: :button,
 *             data: {
 *              action: 'click->columns-settings#applySelection',
 *              columns_settings_target: 'saveButton',
 *             },}= Button
 *
 */

export default class extends Controller {
    static targets = ["toggle", "saveButton"];
    static values = { cookie: String }

    connect() {
        this.loadPreferences();
    }

    toggleRow(event) {
        const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
        checkbox.checked = !checkbox.checked;
        this.saveButtonTarget.disabled = !this.isSelectionChanged();
    }

    loadPreferences() {
        const columnSettings = this.getCookie(this.cookieValue);
        this.columnStates = columnSettings ? JSON.parse(columnSettings) : this.getDefaultColumnStates();
        this.originalStates = { ...this.columnStates };
    }

    getDefaultColumnStates() {
        return Array.from(this.toggleTargets).reduce((states, toggle) => {
            states[toggle.name] = toggle.checked;
            return states;
        }, {});
    }

    savePreferences() {
        this.columnStates = Array.from(this.toggleTargets).reduce((states, toggle) => {
            states[toggle.name] = toggle.checked;
            return states;
        }, {});

        this.setCookie(this.cookieValue, JSON.stringify(this.columnStates), 30);
        this.originalStates = { ...this.columnStates };
    }

    isSelectionChanged() {
        return Array.from(this.toggleTargets).some(toggle => {
            return this.originalStates[toggle.name] !== toggle.checked;
        });
    }

    applySelection() {
        this.savePreferences();
        window.location.reload();
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 86400000).toUTCString();
        document.cookie = `${name}=${value}; expires=${expires}; path=/`;
    }
}