import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches'

/**
 * RefreshFrameController refreshes a frame after the specified interval without user interaction
 */
export default class extends Controller {
    static values = {
        targetUrl: String,
        interval: { type: Number, default: 15000 }
    }

    connect(){
        super.connect();
        fetches(this);

        setTimeout(_ => {
            this.loadFrame()
        }, parseInt(this.data.get('intervalValue')) || 15000)
    }

    loadFrame() {
        this.fetchPartial(this.targetUrlValue).then(res => {
            res.text().then(text => {
                this.element.innerHTML = text;
            })
        })
    }
}