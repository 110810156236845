import {Controller} from "@hotwired/stimulus"
import {fetches} from './mixins/fetches'

/**
 Makes the controlled element sortable by drag'n'drop. The change needs to be
 confirmed by the user.

 Assumes structure:

 .container{data: { controller: 'sortable' }}
   %div{data: {sortable_target: 'flashes'}}

   - items.each do |item|
     .slot{data: { sortable_target: 'slot' }}
       .item{data: { sortable_target: 'item' , sortable_id: item.id }}

   .hidden{data: { sortable_target: 'save' }}
      %button{type: :button,
                   data: { action: 'click->sortable#save' }}

 An item needs to be wrapped in a slot so that a dropzone element can be added
 as a child of the slot and overlay the item. Making the item itself droppable
 doesn't work when the item has any inner structure.

 Flash container will get a flash based on the response.

 Save will overlay a spinner while the request is pending.
 */
export default class extends Controller {
  static targets = ['item', 'slot', 'dropzone', 'save', 'flashes']
  static values = {url: String}
  static classes = ['hidden', 'dropzone', 'successfulFlash', 'failureFlash', 'relative']

  connect() {
    super.connect();
    fetches(this);

    this.saveTarget.classList.add(this.hiddenClass);

    this.itemTargets.forEach(item => item.draggable = true);

    this.slotTargets.forEach(slot => {
      const dropzone = document.createElement('div');
      dropzone.classList.add(this.hiddenClass, ...this.dropzoneClasses);
      dropzone.dataset.sortableTarget = 'dropzone';
      slot.classList.add(this.relativeClass);
      slot.appendChild(dropzone);
    });

    this.element.addEventListener('dragstart', (e) => {
      if (e.target.dataset.sortableTarget !== 'item') {
        return;
      }

      e.dataTransfer.dropEffect = 'move';
      e.dataTransfer.setData('text/plain', e.target.dataset.sortableId);
      e.dataTransfer.setDragImage(e.target, 0, 0);
    });

    this.element.addEventListener('dragend', () => this.hideDropzones());

    this.element.addEventListener('dragenter', e => this.showDropzone(e));
    // not strictly necessary but makes it smoother
    this.element.addEventListener('dragover', e => this.showDropzone(e));

    this.element.addEventListener('dragleave', (e) => {
      const slot = e.target.closest('[data-sortable-target=slot]');
      if (slot) {
        return;
      }
      e.preventDefault();

      this.hideDropzones();
    });

    this.element.addEventListener('drop', (e) => {
      const slot = e.target.closest('[data-sortable-target=slot]');
      if (!slot) {
        return;
      }

      const movedId = e.dataTransfer.getData('text/plain');
      const targetId = slot.querySelector('[data-sortable-target=item]').dataset.sortableId;
      if (movedId === targetId) {
        return;
      }

      const moved = this.element.querySelector(`[data-sortable-id="${movedId}"]`).closest('[data-sortable-target=slot]');
      const target = this.element.querySelector(`[data-sortable-id="${targetId}"]`).closest('[data-sortable-target=slot]');

      let targetPassed = false;
      let movedPassed = false;
      this.itemTargets.forEach(item => {
        if (item.dataset.sortableId === movedId) {
          if (targetPassed) {
            target.parentNode.insertBefore(moved, target);
            return;
          }
          movedPassed = true;
        } else if (item.dataset.sortableId === targetId) {
          if (movedPassed) {
            if (target.nextSibling) {
              target.parentNode.insertBefore(moved, target.nextSibling);
            } else {
              target.parentNode.appendChild(moved);
            }
            return;
          }
          targetPassed = true;
        }
      })

      this.saveTarget.classList.remove(this.hiddenClass);
    });
  }

  save() {
    if (!this.urlValue) {
      console.error('data-sortable-url-value not specified');
      return;
    }

    let order = [];
    this.itemTargets.forEach(item => {
      const id = item.dataset['sortableId'];
      if (id) {
        order.push(id);
      } else {
        console.error('sortable item missing data-sortable-id attr');
      }
    })

    this.saveTarget.dataset.controller = `${this.saveTarget.dataset.controller} spinner`;

    this.fetchJson(
      this.urlValue,
      {
        method: 'PATCH',
        body: JSON.stringify({order: order}),
      }
    ).then(res => {
      if (res.ok) {
        res.text().then(text => this.addFlash(text, this.successfulFlashClasses));
        this.saveTarget.classList.add(this.hiddenClass);
      } else {
        res.text().then(text => this.addFlash(text, this.failureFlashClasses));
      }
    })
      .finally(_ => {
        this.saveTarget.dataset.controller = this.saveTarget.dataset.controller.replace('spinner', '');
      })
  }

  showDropzone(e) {
    const slot = e.target.closest('[data-sortable-target=slot]');
    if (!slot) {
      return;
    }
    e.preventDefault();

    slot.querySelector('[data-sortable-target=dropzone]').classList.remove(this.hiddenClass);
  }

  hideDropzones() {
    this.dropzoneTargets.forEach(dz => {
      dz.classList.add(this.hiddenClass);
    });
  }

  addFlash(msg, cls) {
    const flash = document.createElement('div');
    flash.className = cls;
    flash.innerText = msg;
    this.flashesTarget.appendChild(flash);
    setTimeout(_ => flash.remove(), 5000)
  }
}