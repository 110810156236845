import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['id', 'highlight']
    static classes = ['highlight']

    connect() {
        super.connect();

        const id = this.idTarget.id;
        const idFromUrl = window.location.hash.substring(1);

        if (id === idFromUrl) {
            this.highlightTarget.classList.add(this.highlightClass);
        }
    }
}