/**
 * Syncs the tree menu
 * Goals:
 * 1. On initial page load the tree menu should scroll to the corresponding item
 * 2. Parse the current tree menu links from data attribute and update the hrefs
 * 3. Mark the clicked tree menu item as current
 **/

const syncMenus = () => {
  let treeMenu = document.querySelector('#permanent-tree-menu');

  let treeMenuScrollableContainer = treeMenu?.querySelector('[data-tree-menu-target="scrollContainer"]');
  let treeMenuScrollElement = treeMenu?.querySelector('.current');
  let scrollContainerElement = treeMenu?.querySelector('#scroll-container-element');

  let currentTreeMenu;
  let fetchProgress = { started: false, finished: false };

  /* Initialize treeMenu if it exists */
  /* Prefetch tree menu if it doesn't exist */
  document.addEventListener('readystatechange', async () => {
    if (document.readyState === "complete") {
      if (treeMenu) {
        currentTreeMenu = treeMenu;
      } else {
        await prefetchTreeMenu();
      }
    }
  })

  /**
   * If tree menu prefetch in progress,
   * wait for it to finish before resuming turbo:before-render.
   * Use Object.defineProperty instead if burning setTimeout loop.
   */
  document.addEventListener('turbo:before-render', e => {
    if (fetchProgress.started) {
      e.preventDefault();
      if (fetchProgress.finished) {
        e.detail.resume();
      } else {
        Object.defineProperty(fetchProgress, 'finished', {
          configurable: true,
          enumerable: true,
          get: function () {
            return this._finished;
          },
          set: function (val) {
            this._finished = val;
            e.detail.resume();
          }
        });
      }
    }
  })

  document.addEventListener('turbo:render', e => {
    treeMenu = document.querySelector('#permanent-tree-menu');
    scrollContainerElement = treeMenu?.querySelector('#scroll-container-element');
    if (!scrollContainerElement && treeMenu) {
      /* We came from a layout that doesn't have a sidebar */
      /* Replace the tree menu with saved version */
      treeMenu.replaceWith(currentTreeMenu);
    }

    /* Update tree menu links */
    /* We need to wait one JS cycle after tree menu replaceWith */
    setTimeout(() => {
      scrollContainerElement = treeMenu?.querySelector('#scroll-container-element');
      if (scrollContainerElement) {
        const currentTreeMenuLinks = JSON.parse(document.querySelector('#raw-tree-menu-links')?.dataset.rawTreeMenuLinks || false);
        if (currentTreeMenuLinks) {
          treeMenu.querySelectorAll('a').forEach((link) => {
            const targetLink = currentTreeMenuLinks.find(item => Number(item.id) === Number(link.dataset.id));
            link.setAttribute('href', targetLink.location);
          })
        }
      }
    },0)
  })

  const prefetchTreeMenu = async () => {
    const url = document.querySelector('[data-tree-menu-fetch-path]')?.dataset?.treeMenuFetchPath;
    if (!url) {
      fetchProgress.started = false;
      return;
    }
    fetchProgress.started = true;
    const response = await fetch(url);
    const htmlString = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(JSON.parse(htmlString).html, 'text/html');
    currentTreeMenu = doc.querySelector('#permanent-tree-menu');
    fetchProgress.finished = true
  }
}

export default syncMenus