import {Controller} from "@hotwired/stimulus"

/**
 * Redirect controller redirect the page once it's connected. If no URL is
 * provided, it just reloads the current one.
 */
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    if (this.hasUrlValue) {
      window.location = this.urlValue;
    } else {
      window.location.reload();
    }
  }
}