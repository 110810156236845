import {Controller} from "@hotwired/stimulus"
import {replaceByFetch} from "./mixins/replace_by_fetch";

/**
 * Replace content controller allows to replace html element with the given template
 * 
 * :plain
 *   <script type="text/javascript" data-eval="true">
 *     const event = new CustomEvent(
 *       'replace-content:refreshContent',
 *       { detail: {
 *         templateId: 'receivedInvoices',
 *       } }
 *     );
 *     document.currentScript.closest('[data-controller~="replace-content"]').dispatchEvent(event);
 *   </script>

 * %template#received-invoices
 *  = "this will replace the controlled element"
 */

export default class extends Controller {
  connect() {
    super.connect();
    replaceByFetch(this);

    this.refreshContentHandler = this._refreshContentHandler.bind(this);
    this.element.addEventListener('replace-content:refreshContent', this.refreshContentHandler);
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('replace-content:refreshContent', this.refreshContentHandler);
  }

  _refreshContentHandler(event) {
    const template = document.getElementById(event.detail.templateId);

    document.querySelector('[data-controller~="replace-content"]').outerHTML = template.innerHTML

    template.remove();
  }
}
