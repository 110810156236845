import 'trix'

// Trix translations I18n.t('trix.*')
if (window.I18n.translations[window.I18n.locale] && window.I18n.translations[window.I18n.locale]['trix']) {
  for (const key in window.I18n.translations[window.I18n.locale]['trix']) {
    Trix.config.lang[key] = window.I18n.translations[window.I18n.locale]['trix'][key];
  }
}

document.addEventListener("trix-initialize", function(e) {
  const fileTools = document.querySelector(".trix-button-group--file-tools");
  fileTools?.remove();
});

// Dont allow images/etc to be pasted
document.addEventListener("trix-attachment-add", function(event) {
  if (!event.attachment.file) {
    event.attachment.remove()
  }
})

// No files, ever
document.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
});